import React, { useState, useEffect } from 'react'
import { Button, Popconfirm, Space, Table } from 'antd';
import axios from '../../../utils/clientAxios';
import { DeleteTwoTone, EditTwoTone, FileAddOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';

export default function ContractTemplateTable() {
    const [templates, setTemplates] = useState([]);
    useEffect(()=>{
        axios.get('contracts/templates/')
            .then(response=>{
                setTemplates(response.data);
            })
            .catch(err=>{

            })
    }, []);
    const handleDelete = e => {
        return;
    }
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            defaultSortOrder: 'descend',
            render: (text, record) => {
                return(
                    <Space size='middle'>
                        <span>{text}</span>
                    </Space>
                )
            }
            // sorter: (a, b) => alphabeticOrderSorter(a.name, b.name)
        },
        {
            title: "Description",
            dataIndex: "description",
            defaultSortOrder: 'descend',
            render: (text, record) => {
                return(
                    <>
                        <span>{text}</span>
                    </>
                )
            }
            // sorter: (a, b) => alphabeticOrderSorter(a.name, b.name)
        },
        {
            title: "Actions",
            render: (text, record)=>{
                return (
                    <Space size="middle">
                        <Button type="primary" onClick={()=>{
                            navigate('/contracts/new', { state: { templateId: record.id } })}
                        } icon={<FileAddOutlined />}/>
                        <EditTwoTone />
                        <Popconfirm
                            title="Are you sure you want to delete?"
                            onConfirm={e => {
                                handleDelete(e, record.id)
                            }}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteTwoTone twoToneColor="#f5222d" />
                        </Popconfirm>
                    </Space>
                )
            }
        }

    ]

    return (
        <>
        {(
            templates &&
            <Table dataSource={templates} columns={columns}/>
        )}
        </>
    )
}
