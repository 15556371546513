import React from 'react'
import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ChoosePlanForm from '../../components/forms/ChoosePlanForm/ChoosePlanForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export default function StripeContainer({form, setCurrentStep, finalSubmitHandler}) {

    return (
        <Elements stripe={stripePromise}>
            <ChoosePlanForm form={form} setCurrentStep={setCurrentStep} finalSubmitHandler={finalSubmitHandler}/>
        </Elements>
)
}
