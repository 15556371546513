export const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 8 },
    },
};
export const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 8,
            offset: 4,
        },
    },
};
