import React, { useState, useEffect } from 'react';
import { Layout, Form, Input, Button, Space, DatePicker, message, Select } from 'antd';
import { layout, tailFormItemLayout } from '../../../../utils/layouts';
import styles from '../../../../utils/GlobalStyles.module.scss';
import axios from '../../../../utils/clientAxios';
import PersonSelect from '../../../../components/FormInputs/PersonSelect/PersonSelect';
import ContractForm from '../../../../components/forms/ContractForm/ContractForm';

const { Option } = Select;
const success = (name) => {
    message.success(`${name} has been added`);
};
const error = () => {
    message.error("This person could not be added. Try again")
}

export default function ContractCreate(props) {
    const templateId = props?.location?.state?.templateId;
    console.log(templateId);
    const onSubmitHandler = data => {
        console.log(data)
        axios({
            url: `contracts/`,
            method: 'POST',
            data: data,
            responseType: 'arraybuffer'
        })
            .then(response => {
                let blob = new Blob([response.data], { type: 'application/application/vnd.openxmlformats-officedocument.wordprocessingml.document' }),
                url = window.URL.createObjectURL(blob)
                window.open(url);
            })
            .catch(err => {
                error();
                console.log(err)
            })
    }
    return (
        <Layout className={styles.layout}>
            <Layout.Header>
                <h1 style={{ color: "white" }}>Add Contract</h1>
            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                <ContractForm onSubmitHandler={onSubmitHandler} templateId={templateId} />
            </Layout.Content>
        </Layout>

    )
}
