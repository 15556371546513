import { Alert } from 'antd';
import React, { useState, useEffect } from 'react'
import axios from '../../../utils/clientAxios';
import FileThumbnailComponent from '../FileThumbnailComponent/FileThumbnailComponent';
import styles from './FileListDisplay.module.scss';

export default function FileListDisplay({model, objectId}) {
    const [files, setFiles] = useState([]);
    useEffect(()=>{
        axios.get(`files?model=${model}&object_id=${objectId}`)
            .then(response=>{
                setFiles(response.data);                
            })
            .catch((err)=>{

            })        
    },[])

    return (
        <>
            {files.length === 0 ? 
                <Alert message="No Files" type="info" showIcon />:
                <div className={styles.thumbnails}>
                    {files.map((item, index)=>{
                    return (
                        <FileThumbnailComponent files={files} setFiles={setFiles} index={index} file={{extension: item.extension, name: item.file_name, id: item.id, url: item.url}}/>    
                    );
                })}
                </div>
            }  
        </>
    )
}
