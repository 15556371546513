import React from 'react';
import { Layout, Form, message } from 'antd';
import styles from '../../../../utils/GlobalStyles.module.scss';
import axios from '../../../../utils/clientAxios';
import TrademarkForm from '../../../../components/forms/TrademarkForm/TrademarkForm';
import { navigate } from '@reach/router';


export default function TrademarkCreate() {
    const [form] = Form.useForm();
    const onSubmitHandler = data => {
        data.application_date = data.application_date.format('YYYY-MM-DD');
        data.office_actions_date = data.office_actions_date.format('YYYY-MM-DD');
        data.registration_date = data.registration_date.format('YYYY-MM-DD');
        data.renewal_date = data.renewal_date.format('YYYY-MM-DD');
        data.response_date = data.response_date.format('YYYY-MM-DD');
        data.publication_start_date = data.publication_start_date.format('YYYY-MM-DD');
        data.publication_end_date = data.publication_end_date.format('YYYY-MM-DD');
        data.notice_of_allowance = data.notice_of_allowance.format('YYYY-MM-DD');
        data.extension_due_date = data.extension_due_date.format('YYYY-MM-DD');

        axios.post(`trademarks/`, data)
            .then(response => {
                message.success("Trademark has been added");
                navigate(`/trademarks/${response.data.id}`)
            })
            .catch(err => {
                console.log(err);
            })
    }
    return (
        <Layout className={styles.layout}>
            <Layout.Header>
                <h1 style={{ color: "white" }}>Add Trademark</h1>
            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                <TrademarkForm onSubmitHandler={onSubmitHandler} />
            </Layout.Content>
        </Layout>

    )
}
