import React, { useEffect } from 'react';
import url from 'url';

import {navigate, useLocation} from '@reach/router';
import axios from '../../../../utils/clientAxios';
import { message } from 'antd';

export default function RegistrationConfirmView() {
    const location = useLocation();

    useEffect(()=>{
        const url = new URL(location.href);
        const sessionId = url.searchParams.get('session_id');
        axios.post('auth/set-organization-status/', { sessionId: sessionId })
            .then(response=>{
                navigate('/dashboard')
            })
            .catch(err=>{
                message.error("Could not log in. Please try logging in")
            })
    }, [])

    return (
        <div>
            Loading...        
        </div>
    )
}
