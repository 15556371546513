import React, { useState, useEffect } from 'react';
import { Table, Button, Card, Space, message } from 'antd';
import { layout, tailFormItemLayout } from '../../utils/layouts';
import { PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import OrganizationMemberModal from '../modals/OrganizationMemberModal/OrganizationMemberModal';
import axios from '../../utils/clientAxios';

export default function OrganizationManagementTable() {
    const [organizationMetadata, setOrganizationMetadata] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(()=>{
        axios.get('organizations/meta/')
            .then(response=>{
                setOrganizationMetadata(response.data);
            })
            .catch(err=>{

            })
    }, [])

    const userTableColumns = [
        {
            title: 'First Name',
            dataIndex: 'first_name'
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Role',
            dataIndex: 'organization_role'
        }
    ]

    const showModal = () => {
        setVisible(true);
    }
    const addNewPerson = (data) => {
        axios.post('auth/add-user/', data)
        .then(response=>{
            setVisible(false);
        })
        .catch(err=>{
            message.error(err.response.data['non_field_errors'].join("\n"))
        })
    }
    return (
        <>
           { organizationMetadata && 
                <Card title={`Members: ${organizationMetadata.members.length}/${organizationMetadata.total_licenses_allowed}`} extra={
                    <Space>
                        <Button icon={<PlusCircleOutlined />} type="primary" onClick={showModal}>Add Member</Button>
                        <Button type="primary">Upgrade Plan</Button>
                    </Space>
                }>
                    <Table columns={userTableColumns} dataSource={organizationMetadata.members} />
                    <OrganizationMemberModal visible={visible} setVisible={setVisible} onSubmitHandler={addNewPerson}/>
                </Card>
            }
        </>
    )
}
