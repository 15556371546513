import React from 'react'
import { Layout, Row, Button } from 'antd'
import InvoiceListTable from '../../../../components/tables/InvoiceListTable/InvoiceListTable'
import {navigate} from '@reach/router';

export default function InvoiceList() {
    return (
        <Layout>
            <Layout.Header>
                <Row justify="space-between" align="middle">
                    <h1 style={{ color: "white" }}>Invoices</h1>
                    <Button type="primary" onClick={e => { navigate('/invoices/new') }}>Add Invoice</Button>
                </Row>
            </Layout.Header>
            <Layout.Content>
                <InvoiceListTable/>
            </Layout.Content>
        </Layout>
    )
}
