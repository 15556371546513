import React, { useEffect, useState } from 'react'
import { Layout, Row, Typography, Col, Button, Divider, Descriptions, Tag, Tabs } from 'antd';
import { PlusCircleOutlined, UploadOutlined, EditOutlined } from '@ant-design/icons'
import axios from '../../../../utils/clientAxios';
import styles from '../../../../utils/GlobalStyles.module.scss';
import NewNoteModal from '../../../../components/modals/NewNoteModal/NewNoteModal';
import NotesDisplayTable from '../../../../components/Display/NotesDisplayTable';
import { navigate } from '@reach/router';
import FileComponent from '../../../../components/Display/FileComponent/FileComponent';
import { ModelMapping } from '../../../../utils/utils';
import { trademarkChoices } from '../../../../utils/choiceMapping';
import TrademarkStatusTag from '../../../../components/TrademarkStatusTag/TrademarkStatusTag';
import globalStyles from '../../../../utils/GlobalStyles.module.scss';

export default function TrademarkDetail({ slug }) {
    const [trademark, setTrademark] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    useEffect(() => {
        axios.get(`trademarks/${slug}`)
            .then(response => {
                response.data.classes.sort((a, b) => {
                    var a = parseInt(a.slice(a.length - 3, a.length));
                    var b = parseInt(b.slice(b.length - 3, b.length));
                    return a - b;
                });
                setTrademark(response.data)
            })
            .catch(err => {

            })
    }, [])

    const onSubmitNoteHandler = (e, text) => {
        axios.post(`notes/`, {
            object_id: trademark.id,
            model_name: 'trademark',
            text: text
        });
    }
    return (
        <>
            {trademark &&
                <>
                    <Layout className={globalStyles.layout}>
                        <Layout.Header>
                            <Row justify="space-between" align="middle">
                                <h1 style={{ color: "white" }}>{trademark.name}</h1>
                                <Button type="primary" onClick={() => navigate(`/trademarks/${slug}/edit/`)} icon={<EditOutlined />}>Edit {trademark.name}</Button>
                            </Row>
                        </Layout.Header>
                        <Layout.Content className={styles.layout_content}>
                            <Tabs defaultActiveKey="1">
                                <Tabs.TabPane tab="Trademark Details" key="1">
                                    <Row>
                                        <Descriptions title="Trademark Info">
                                            <Descriptions.Item label="Name">{trademark.name}</Descriptions.Item>
                                            <Descriptions.Item label="Status">
                                                <TrademarkStatusTag
                                                    status={trademark.status}
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Jurisdiction">{trademark.jurisdiction}</Descriptions.Item>
                                            <Descriptions.Item label="Type">{trademarkChoices.typeMapping[trademark.trademark_type]}</Descriptions.Item>
                                            <Descriptions.Item label="Registration Number">{trademark.registration_number}</Descriptions.Item>
                                            <Descriptions.Item label="Serial Number">{trademark.serial_number}</Descriptions.Item>
                                        </Descriptions>
                                        <Descriptions>
                                            <Descriptions.Item label="Next Steps">{trademark.next_steps}</Descriptions.Item>
                                        </Descriptions>
                                        <Descriptions>
                                            <Descriptions.Item label="Description of Goods/Services">{trademark.description_of_goods}</Descriptions.Item>
                                        </Descriptions>
                                        <Descriptions>
                                            <Descriptions.Item label="Classes">
                                                <div style={{ overflow: "wrap" }}>
                                                    {trademark.classes.map((item, index) => {
                                                        return (
                                                            <Tag key={index}>{trademarkChoices.classesMapping[item]}</Tag>
                                                        )
                                                    })}
                                                </div>
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Descriptions title="Important Dates">
                                            <Descriptions.Item label="Application Date">{trademark.application_date}</Descriptions.Item>
                                            <Descriptions.Item label="Registration Date">{trademark.registration_date}</Descriptions.Item>
                                            <Descriptions.Item label="Renewal Date">{trademark.renewal_date}</Descriptions.Item>
                                            <Descriptions.Item label="Office Actions Date">{trademark.office_actions_date}</Descriptions.Item>
                                            <Descriptions.Item label="Response Date">{trademark.response_date}</Descriptions.Item>
                                            <Descriptions.Item label="Publication Start Date">{trademark.publication_start_date}</Descriptions.Item>
                                            <Descriptions.Item label="Publication End Date">{trademark.publication_end_date}</Descriptions.Item>
                                            <Descriptions.Item label="Notice of Allowance">{trademark.notice_of_allowance}</Descriptions.Item>
                                            <Descriptions.Item label="Extension Due Date">{trademark.extension_due_date}</Descriptions.Item>
                                        </Descriptions>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Notes" key="3">
                                    <Row justify="space-between">
                                        <Typography.Title level={3}>Notes</Typography.Title>
                                        <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => setVisibleModal(true)}>Add Note</Button>
                                    </Row>
                                    <NotesDisplayTable model={ModelMapping.trademark} objectId={trademark.id} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Files" key="2">
                                    <FileComponent model={ModelMapping.trademark} objectId={trademark.id} />
                                </Tabs.TabPane>
                            </Tabs>
                        </Layout.Content>
                    </Layout>
                    <NewNoteModal
                        visible={visibleModal}
                        setVisible={setVisibleModal}
                        onSubmitHandler={onSubmitNoteHandler}
                    />
                </>
            }
        </>
    )

}
