import React from 'react'
import { Layout } from 'antd';
import DashboardNavbar from '../../components/DashboardNavbar/DashboardNavbar'

import styles from './NavbarWrapper.module.scss';

const { Content } = Layout;

export default function NavbarWrapper({ children }) {
    return (
        <Layout style={{ height: "100vh" }}>
            <DashboardNavbar />
            <Layout>
                <Content className={styles.content_wrapper}>
                    {children}
                </Content>
            </Layout>
        </Layout>
    )
}
