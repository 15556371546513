import React, { useState, useEffect } from 'react';
import { Form, Button, Input, DatePicker, Space, Select } from 'antd';
import { layout, tailFormItemLayout } from '../../../utils/layouts';
import axios from '../../../utils/clientAxios';
import PersonSelect from '../../FormInputs/PersonSelect/PersonSelect';
import { displayDateOrToday } from '../../../utils/utils';
import CompanyPersonInput from '../../FormInputs/CompanyPersonInput/CompanyPersonInput';
import CompanyInput from '../../FormInputs/CompanyInput/CompanyInput';

export default function PatentForm({ onSubmitHandler, patent }) {
    console.log(patent)
    const [form] = Form.useForm();
    const [patentStatuses, setPatentStatuses] = useState({});
    const [patentTypes, setPatentTypes] = useState({});
    const [jurisdictions, setJurisdictions] = useState({});

    useEffect(() => {
        axios.get('core/choices/?model=patents,countries')
            .then(response => {
                setPatentStatuses(response.data.patents.patent_statuses);
                setPatentTypes(response.data.patents.patent_types);
                setJurisdictions(response.data.countries.countries);
            })
            .catch(err => {

            })
    }, []);

    const onSubmit = data => {
        console.log(data)
        data.assignees = data.assignees.map((item, index)=>{
            return JSON.parse(item);
        })
        data.inventors = data.inventors.map((item, index)=>{
            return JSON.parse(item);
        })
        // data.law_firm = JSON.parse(data.law_firm);
        onSubmitHandler(data)
    }

    return (
        <Form
            {...layout}
            form={form}
            onFinish={onSubmit}
        >

            <Form.Item
                label="Title"
                name="title"
                // rules={[{ required: true, message: "This field is required" }]}
                initialValue={patent?.title}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Product"
                name="product"
                // rules={[{ required: true, message: "This field is required" }]}
                initialValue={patent?.product}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Firm Reference Number"
                name="firm_reference_number"
                initialValue={patent?.firm_reference_number}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Status"
                name="status"
                initialValue={patent?.status}
            >
                <Select>
                    {Object.keys(patentStatuses).map((item, index) => {
                        return <Select.Option key={index} value={item}>{patentStatuses[item]}</Select.Option>
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label="Jurisdiction"
                name="jurisdiction"
                initialValue={patent?.jurisdiction}
            >
                <Select>
                    {Object.keys(jurisdictions).map((item, index) => {
                        return <Select.Option key={index} value={item}>{jurisdictions[item]}</Select.Option>
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label="Type"
                name="patent_type"
                initialValue={patent?.patent_type}
            >
                <Select>
                    {Object.keys(patentTypes).map((item, index) => {
                        return <Select.Option key={index} value={item}>{patentTypes[item]}</Select.Option>
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label="Application Number"
                name="application_number"
                initialValue={patent?.application_number}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Filing Date"
                name="filing_date"
                initialValue={displayDateOrToday(patent?.filing_date)}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                label="Priority Date"
                name="priority_date"
                initialValue={displayDateOrToday(patent?.priority_date)}
            >
                <DatePicker format='YYYY-MM-DD' />
            </Form.Item>
            <Form.Item
                label="Publication Number"
                name="publication_number"
                initialValue={patent?.publication_number}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Issue Date"
                name="issue_date"
                initialValue={displayDateOrToday(patent?.issue_date)}
            >
                <DatePicker format='YYYY-MM-DD' />
            </Form.Item>
            <Form.Item
                label="Renewal Fee Date"
                name="renewal_fee_date"
                initialValue={displayDateOrToday(patent?.renewal_fee_date)}
            >
                <DatePicker format='YYYY-MM-DD' />
            </Form.Item>
            <Form.Item
                label="Patent Number"
                name="patent_number"
                initialValue={patent?.patent_number}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Expiration Date"
                name="expiration_date"
                initialValue={displayDateOrToday(patent?.expiration_date)}
            >
                <DatePicker format='YYYY-MM-DD' />
            </Form.Item>
            <CompanyPersonInput defaultValue={patent?.inventors} form={form} fieldName="inventors" label="Inventor(s)" mode="multiple"/>
            <CompanyPersonInput defaultValue={patent?.assignees} form={form} fieldName="assignees" label="Assignee(s)" mode="multiple"/>
            <CompanyInput form={form} defaultValue={patent?.law_firm} fieldName="law_firm" label="Law Firm" />
            <Form.Item {...tailFormItemLayout}>
                <Space size="middle">
                    <Button type="primary" htmlType="submit">Submit</Button>
                    <Button type="primary" danger href={patent?.id ? `/patents/${patent.id}`: `/patents`}>Cancel</Button>
                </Space>
            </Form.Item>
        </Form>
    )
}
