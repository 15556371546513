import { Link } from '@reach/router';
import { Table, Space, Popconfirm, message } from 'antd';
import React, { useState, useEffect } from 'react'
import axios from '../../../utils/clientAxios';
import {navigate} from '@reach/router';
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';

export default function CompanyTable() {
    const [companies, setCompanies] = useState([]);
    const [entityTypes, setEntityTypes] = useState({});
    const [states, setStates] = useState({});
    const [statuses, setStatuses] = useState({});
    
    const fetchCompanies = () => {
        axios.get('companies/')
            .then(response=>{
                setCompanies(response.data);                
            })
            .catch(err=>{
                
            })
    }

    useEffect(()=>{
        fetchCompanies();
        axios.get('core/choices?model=companies,states')
            .then(response=>{
                setEntityTypes(response.data.companies.entity_types);
                setStates(response.data.states.all);
                setStatuses(response.data.companies.statuses);
            })
    }, [])

    const deleteCompany = id => {
        axios.delete(`companies/${id}`)
            .then(response=>{
                message.success("Company deleted");
                fetchCompanies();
            })
            .catch(err=>{
                message.error("Something went wrong. Please try again.")
            })
    }

    const columns = [
        {
            title: "Name",
            render: (text, record) => {
                return <Link to={`/companies/${record.id}`}>{record.name}</Link>
            }
        },
        {
            title: "Entity Type",
            render: (text, record) => {
                return <span>{entityTypes[record?.entity_type]}</span>
            }
        },
        {
            title: "State of Incorporation",
            render: (text, record) => {
                return <span>{states[record?.state_of_incorporation]}</span>
            }
        },
        {
            title: "Status",
            render: (text, record) => {
                return <span>{statuses[record?.status]}</span>
            }
        },
        {
            title: "Entity Number",
            dataIndex: "entity_number"
        },
        {
            title: "Actions",
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <EditTwoTone onClick={e => { navigate(`/companies/${record.id}/edit`) }} />
                        <Popconfirm
                            title="Are you sure you want to delete this record?"
                            onConfirm={e=>{ deleteCompany(record.id)}} 
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteTwoTone twoToneColor="#f5222d" />
                        </Popconfirm>
                    </Space>
                )    
            }
        }
    ]
    
    return (
        <Table columns={columns} dataSource={companies}/>
    )
}
