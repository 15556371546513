import React, { useContext } from 'react';
import GlobalContext from '../../../store/GlobalContext';
import { Layout, Menu, Row, Col, Statistic, Card } from 'antd';
import DashboardNavbar from '../../../components/DashboardNavbar/DashboardNavbar';
import NavbarWrapper from '../../../containers/NavbarWrapper/NavbarWrapper';
import CompanyTable from '../../../components/tables/CompanyTable/CompanyTable';
const { Header, Content, Footer, Sider } = Layout;

export default function DashboardView() {
    const { globalState: { authState } } = useContext(GlobalContext);
    const { organization } = authState;

    return (
        <Layout>
            <Layout.Content style={{ minHeight: "90vh" }}>
                <Row justify="space-between">
                    <Col>
                        <Statistic title="Organization" value={organization?.name} />
                    </Col>
                    <Col>
                        Image
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col>
                        <Card title="Entity Information">

                        </Card>
                    </Col>
                    <Col>
                        <Card title="Important Dates">

                        </Card>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col>Documents</Col>
                </Row>
                <Row justify="space-between">
                    <h1>Recent Activity</h1>
                </Row>
                <Row>
                    <CompanyTable />
                </Row>
            </Layout.Content>
        </Layout>
    )
}

