import { Form, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import axios from '../../../utils/clientAxios';

export default function AnnualFilingFeeStatusInput({fieldName, form, defaultValue, label}) {
    const [choices, setChoices] = useState([]);

    useEffect(() => {
        axios.get('core/choices?model=companies')
            .then(response=>{
                setChoices(response.data.companies.annual_filing_fee_statuses);
            })
            .catch(err=>{

            })
        
    }, [])

    const onChange = e => {
        form.setFieldsValue({ [fieldName]: e });
    }

    return (
        <Form.Item
            label={label}
            name={fieldName}
            initialValue={defaultValue}
        >
            <Select
                onChange={onChange}
            >
                {Object.keys(choices).map((key, index)=>{
                    return <Select.Option value={key}>{choices[key]}</Select.Option>
                })}
            </Select>
        </Form.Item>
)
}
