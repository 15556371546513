import React, { useContext } from 'react'
import { Button, Form, Input } from 'antd'
import { BankOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';
import axios from '../../../utils/clientAxios';
import GlobalContext from '../../../store/GlobalContext';


export default function UserRegistrationForm({setCurrentStep}) {
    const [form] = Form.useForm();
    const {globalState, globalDispatch} = useContext(GlobalContext);

    const onSubmitHandler = data => {
        axios.post('auth/register/', data)
            .then(response => {
                // message.success("Login Successful")
                globalDispatch({ type: 'set_user_data', payload: { username: response.data.email, organization: response.data.organization, isAuthenticated: false } })
                setCurrentStep(1);
            })
            .catch(err => {

            })
    }

    return (
        <Form
            form={form}
            onFinish={onSubmitHandler}
        >
            <Form.Item name="email">
                <Input prefix={<MailOutlined />} placeholder="Email Address" />
            </Form.Item>
            <Form.Item name="organization">
                <Input type="text" prefix={<BankOutlined />} placeholder="Organization Name" />
            </Form.Item>
            <Form.Item name="password">
                <Input type="password" prefix={<LockOutlined />} placeholder="Password" />
            </Form.Item>
            <Form.Item name="confirm_password">
                <Input type="password" prefix={<LockOutlined />} placeholder="Confirm Password" />
            </Form.Item>
            <Button type="primary" htmlType="submit" block>Register</Button>
            <Link to="/login">Already have an account? Login</Link>
        </Form>
    )
}
