import { LOGIN_USER, LOGOUT_USER, STORE_USER_DATA } from '../actions/authActions';


export default (state, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                authState: {
                    isAuthenticated: true,
                    email: action.payload.email
                }
            };
        case LOGOUT_USER:
            return {
                ...state,
                authState: {
                    isAuthenticated: null,
                    email: null
                }
            };
        case STORE_USER_DATA:
            return {
                ...state,
                authState: {
                    ...state.authState,
                    email: action.payload?.email,
                    organization: action.payload?.organization,
                    username: action.payload?.username,
                    firstName: action.payload?.first_name,
                    lastName: action.payload?.last_name,
                    isAuthenticated: action.payload?.isAuthenticated
                }
            }
        default:
            return {
                ...state
            }
    }
}
