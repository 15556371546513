import { Layout } from 'antd';
import React from 'react';
import InvoiceForm from '../../../../components/forms/InvoiceForm/InvoiceForm';
import styles from '../../../../utils/GlobalStyles.module.scss';

export default function InvoiceCreate() {
    return (
        <Layout className={styles.layout}>
            <Layout.Header>
                <h1 style={{ color: "white" }}>Create Invoice</h1>
            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                <InvoiceForm />
            </Layout.Content>
        </Layout>
    )
}
