import authReducer from './authReducer';
import permissionReducer from './permissionReducer';
import modalReducer from './modalReducer';

const combineReducers = (reducers) => {
    return (state, action) => {
        for (const reducer of reducers) {
            state = reducer(state, action)
        }
        return state;
    }
}

// combine reducers into one 
export default combineReducers([
    authReducer,
    permissionReducer,
    modalReducer
])