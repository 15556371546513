import axios from 'axios';

import { message } from 'antd';

import { navigate } from '@reach/router';

const https = require('https');

const clientAxios = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    httpsAgent: new https.Agent({
        rejectUnauthorized: `${process.env.REACT_APP_REJECT_UNAUTHORIZED}`
    }),
    withCredentials: true
});

clientAxios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response) {
        if (error.response.status in [401, 403]) {
            navigate('/login');
        }
        else if (error.response.status in [400, 404]) {
            let errorMessage = "";
            for (const errorFieldName of Object.keys(error.response.data)) {
                errorMessage += `${errorFieldName}: ${error.response.data[errorFieldName]}`;
            }
            message.error(errorMessage);
            // console.log(error.response)
        }
    }
    if (!error.response) {
        navigate('/login');
    }
    return Promise.reject(error);
});

export default clientAxios;