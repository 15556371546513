import React, { useEffect, useState } from 'react';
import axios from '../../utils/clientAxios';
import { dateFormat } from '../../utils/utils';
import moment from 'moment'
import { Alert, Comment } from 'antd';
import styles from './NotesDisplayTable.module.scss';

const ExpandableContent = ({ text }) => {
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => {
        setExpanded(!expanded);
    }
    return (
        <span>
            {expanded ?
                <>
                    {text} <span onClick={toggleExpanded}>Close</span>
                </>
                :
                <>
                    {text.slice(0, 100)} <span onClick={toggleExpanded}>See More...</span>
                </>
            }
        </span>
    )
}
export default function NotesDisplayTable({ model, objectId }) {
    const [notes, setNotes] = useState([]);

    useEffect(() => {
        axios.get(`notes/?model=${model}&object_id=${objectId}`)
            .then(response => {
                setNotes(response.data);
            })
    }, [])
    return (
        <>
            {notes.length === 0 ?
                <Alert style={{ width: "25%" }} message="No Notes" type="info" showIcon />
                :
                notes.map((item, index) => {
                    return (
                        <Comment
                            className={styles.comment}
                            author={<span>Written by: {item.written_by}</span>}
                            content={<ExpandableContent text={item.text} />}
                            datetime={item.created}
                        />
                    )
                })
            }

        </>
    )
}
