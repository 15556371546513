import React, { useContext, useState } from 'react'
import { Grid, Layout, Row, Col, Form, Typography, Card, Input, Button, message } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Link, navigate } from '@reach/router';
import styles from './ForgotPassword.module.scss';
import GlobalContext from '../../../../store/GlobalContext';
import axios from '../../../../utils/clientAxios';

export default function ForgotPasswordView() {
    const [form] = Form.useForm()
    const { globalDispatch } = useContext(GlobalContext);
    const onSubmitHandler = data => {
        axios.post('auth/forgot-password/', data)
            .then(response => {
                message.success("Login Successful")
                console.log(response.data)
                // globalDispatch({ type: 'login', payload: { email: response.data.email, isAuthenticated: true } })
            }).then(() => {
                navigate('/dashboard')
            })
            .catch(err => {
                message.error("The login credentials you provided were invalid")
            })
    }
    return (
        <Layout className={styles.form_layout}>
            <Row justify="center" align="middle" className={styles.row}>
                <Col>
                    <Card title="Forgot Password?" className={styles.form_card}>
                        <Form
                            form={form}
                            onFinish={onSubmitHandler}>
                            <Form.Item name="email" extra="Enter the e-mail associated with your account">
                                <Input prefix={<MailOutlined />} placeholder="Email Address" />
                            </Form.Item>
                            <Button type="primary" block htmlType="submit">Submit</Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}
