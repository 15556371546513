import { SHOW_MODAL, HIDE_MODAL, SET_CANCEL_METHOD, SET_SUBMIT_METHOD, SET_TEXT, SETUP_MODAL } from '../actions/modalActions';


export default (state, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...state,
                modalState: {
                    ...state.modalState,
                    showModal: true
                }
            };
        case HIDE_MODAL:
            return {
                ...state,
                modalState: {
                    ...state.modalState,
                    showModal: false
                }
            };
        case SET_CANCEL_METHOD:
            return {
                ...state,
                modalState: {
                    ...state.modalState,
                    cancelMethod: action.payload.cancelMethod
                }
            };
        case SET_SUBMIT_METHOD:
            return {
                ...state,
                modalState: {
                    ...state.modalState,
                    submitMethod: action.payload.submitMethod
                }
            };
        case SET_TEXT:
            return {
                ...state,
                modalState: {
                    ...state.modalState,
                    text: action.payload.text
                }
            };
        case SETUP_MODAL:
            return {
                ...state,
                modalState: {
                    ...state.modalState,
                    submitMethod: action.payload.submitMethod,
                    cancelMethod: action.payload.cancelMethod,
                    text: action.payload.text,
                    contactPerson: action.payload.contactPerson,
                    invoiceId: action.payload.invoiceId
                }
            };
        default:
            return {
                ...state
            }
    }
}
