import React from 'react'
import { Form, Input, Button, Space, Switch } from 'antd';
import { layout, tailFormItemLayout } from '../../../utils/layouts';
import StateDropdownInput from '../../FormInputs/StateDropdownInput/StateDropdownInput';
import CancelFormButton from '../../buttons/CancelFormButton/CancelFormButton';

export default function PeopleForm({ onSubmitHandler, person }) {
    const [form] = Form.useForm();
    return (

        <Form
            {...layout}
            form={form}
            onFinish={onSubmitHandler}
        >

            <Form.Item
                label="First Name"
                name="first_name"
                rules={[{ required: true, message: "This field is required" }]}
                initialValue={person?.first_name}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Last Name"
                name="last_name"
                rules={[{ required: true, message: "This field is required" }]}
                initialValue={person?.last_name}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Email"
                name="email_address"
                initialValue={person?.email_address}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Primary Phone"
                name="primary_phone_number"
                initialValue={person?.primary_phone_number}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Secondary Phone"
                name="secondary_phone_number"
                initialValue={person?.secondary_phone_number}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Job Title"
                name="job_title"
                initialValue={person?.job_title}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Address Line 1"
                name="address_line_1"            
                initialValue={person?.address_line_1}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Address Line 2"
                name="address_line_2"            
                initialValue={person?.address_line_2}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="City"
                name="city"            
                initialValue={person?.city}
            >
                <Input />
            </Form.Item>
            <StateDropdownInput fieldName="state" form={form} defaultValue={person?.state} label="State"/>
            <Form.Item
                label="Zip Code"
                name="zip_code"            
                initialValue={person?.zip_code}
            >
                <Input />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
                <Space size="middle">
                    <Button type="primary" htmlType="submit">Submit</Button>
                    <CancelFormButton previous={person ? `/people/${person.id}`: false} defaultPrevious='/people' form={form}/>
                </Space>
            </Form.Item>
        </Form>
    )
}
