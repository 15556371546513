import { SendOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Row } from 'antd'
import React, { useContext, useEffect } from 'react'
import GlobalContext from '../../store/GlobalContext'
import { SETUP_MODAL, HIDE_MODAL, SHOW_MODAL } from '../../store/actions/modalActions';

export default function InvoiceDetailHeader({invoice}) {
    const {globalState: { modalState }, globalDispatch } = useContext(GlobalContext);
    useEffect(() => {
        console.log("TEST2", invoice.contact_person)
        globalDispatch({type: SETUP_MODAL, payload: {
            text: `This will be sent to the following:`,
            cancelMethod: ()=>{
                globalDispatch({type: HIDE_MODAL});
            },
            submitMethod: ()=>{
                alert("submitted!");
                globalDispatch({type: HIDE_MODAL});
            },
            contactPerson: invoice.contact_person,
            invoiceId: invoice.id
        }});
    },[])

    return (
        <Row justify="space-between" align="middle">
            <span>Test</span>
            <Popconfirm
                title="Are you sure you want to send this invoice?"
                onConfirm={()=>globalDispatch({type: SHOW_MODAL })}
            >
                <Button type="primary" icon={<SendOutlined/>}>Send</Button>
            </Popconfirm>
        </Row>
    )
}
