import React, { useState, useEffect } from 'react';
import { Layout, Form, Input, Button, Space, DatePicker, message, Select, Upload } from 'antd';
import { layout, tailFormItemLayout } from '../../../utils/layouts';
import { displayDateOrToday } from '../../../utils/utils';
import axios from '../../../utils/clientAxios';
import TextArea from 'antd/lib/input/TextArea';
import { FileWordOutlined, InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

export default function ContractTemplateForm({ onSubmitHandler }) {
    const [form] = Form.useForm();
    const [variableClasses, setVariableClasses] = useState(['variable-1']);
    const [file, setFile] = useState(false);

    const contractTemplateFormSubmitHandler = data => {
        // If there is one class provided, it will just be a string, so convert to an array
        const transformedData = transformData(data);
        console.log(transformedData)
        onSubmitHandler(transformedData);
    }

    const addNewRow = () => {
        const currentLength = variableClasses.length;
        const newClassName = `variable-${currentLength + 1}`;
        setVariableClasses([...variableClasses, newClassName]);
    }
    const transformData = data => {
       const dataKeys = Object.keys(data);
       let transformedData = {
           'template': file,
           'variable_names': []
       };
       console.log(transformedData)
       for (const key of dataKeys) {
            if (key.includes('variable-')) {
                transformedData['variable_names'].push(data[key]);
            }
            else if (key !== 'template'){
                transformedData[key] = data[key];
            }
       }
       return transformedData;
    }
    const beforeUploadHandler = (file) => {
        let reader = new FileReader();
        console.log(file)
        reader.onload = (e) => {
            setFile({
                data: e.target.result,
                name: file.name
            })
        }
        reader.readAsDataURL(file);
        return false;
    }
    const onChange = info => {
        return 
    }
    return (
        <Form
            {...layout}
            form={form}
            onFinish={contractTemplateFormSubmitHandler}
        >
            <Form.Item
                label="Name"
                name="name"
                // initialValue={}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Description"
                name="description"
                // initialValue={}
            >
                <TextArea/>
            </Form.Item>
            <Form.Item label="Template">
                <Form.Item
                    // name="template"
                    // getValueFromEvent={transformFile}
                    >
                    <Upload.Dragger
                        name="file"
                        listType="picture"
                        beforeUpload={beforeUploadHandler}
                        multiple={false}
                    >
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload.
                        </p>
                    </Upload.Dragger>
                </Form.Item>
            </Form.Item>
            <Button onClick={addNewRow}>Add Another Variable</Button>
            {variableClasses.map((item, index)=>{
                return(
                    <Form.Item
                        name={item}
                        label={`Variable Name ${index + 1}`}
                    >
                        <Input/>
                    </Form.Item>
                )
            })}
            <Form.Item {...tailFormItemLayout}>
                <Space size="middle">
                    <Button type="primary" htmlType="submit">Submit</Button>
                    <Button type="primary" danger>Cancel</Button>
                </Space>
            </Form.Item>
        </Form>)
}
