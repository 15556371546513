import React, { useState } from 'react'
import { FileImageOutlined, FileWordOutlined, FileExcelOutlined, FileOutlined, FilePptOutlined, FilePdfOutlined, DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import styles from './FileThumbnailComponent.module.scss';
import axios from '../../../utils/clientAxios';
import { Popconfirm } from 'antd';

export default function FileThumbnailComponent({file, setFiles, files, index}) {
    const [ showDownloadModal, setShowDownloadModal ] = useState(false);
    const [ showActions, setShowActions ] = useState(false);
    const { extension, name, id, url } = file;

    const extensionMapping = {
        docx: <FileWordOutlined />,
        doc: <FileWordOutlined />,
        xls: <FileExcelOutlined />,
        xlsx: <FileExcelOutlined />,
        csv: <FileExcelOutlined />,
        ppt: <FilePptOutlined />,
        pptx: <FilePptOutlined />,
        jpg: <FileImageOutlined />,
        jpeg: <FileImageOutlined />,
        png: <FileImageOutlined />,
        tif: <FileImageOutlined />,
        tiff: <FileImageOutlined />,
        gif: <FileImageOutlined />,
        bmp: <FileImageOutlined />,
        pdf: <FilePdfOutlined />,
        none: <FileOutlined />
    }

    const fileIcon = extensionMapping.hasOwnProperty(extension) ? extensionMapping[extension] : extensionMapping.none;

    const downloadHandler = e => {
        window.open(url);
    }

    const deleteHandler = e => {
        axios.delete(`files/${id}/`)
            .then(res=>{
                setFiles([...files.slice(0,index)] + [...files.slice(index+1, files.length)])
            })
            .catch(err=>console.log)
    }

    const displayActions = e => {
        setShowActions(true);
    }

    const hideActions = e => {
        setShowActions(false);
    }

    return (
        <div className={styles.thumbnail} onMouseEnter={displayActions} onMouseLeave={hideActions}>
            {showActions ?
                <div className={styles.actions}>
                    <DownloadOutlined onClick={downloadHandler} className={styles.download_icon} />
                    <Popconfirm
                        placement="rightTop"
                        title={`Are you sure you want to delete ${name}?`}
                        onConfirm={deleteHandler}
                        okText="Yes"
                        cancelText="No"                
                    >
                        <DeleteOutlined className={styles.delete_icon} />
                    </Popconfirm>
                </div> :
                <div className={styles.display}>
                    <div className={styles.icon}>{fileIcon}</div>
                    <div className={styles.name}>{name}</div>
                </div>
            }
        </div>
    )
}
