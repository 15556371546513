import React, { useState, useEffect} from 'react';

import { Button, Table } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import axios from '../../../utils/clientAxios';
import { Link } from '@reach/router';

export default function EmployeeTable() {
    const [employees, setEmployees] = useState([]);
    useEffect(() => {
        axios.get('employees')
            .then(response=>{
                setEmployees(response.data);
            })
            .catch(err=>{

            })
    }, [])
    const columns = [
        {
            title: "First Name",
            render: (text, record) => {
                return <Link to={`/employees/${record.id}`}>{record.person.first_name}</Link>
            }
        },
        {
            title: "Last Name",
            render: (text, record) => {
                return <span>{record.person.last_name}</span>
            }
        },
        {
            title: "Email Address",
            render: (text, record) => {
                return <span>{record.person.email_address}</span>
            }
        },
        {
            title: "Job Title",
            render: (text, record) => {
                return <span>{record.person.job_title}</span>
            }
        },
        {
            title: "Date Hired",
            dataIndex: "hire_date"
        },
        {
            title: "Actions",
            dataIndex: "hire_date"
        },    
    ]

    return (
        <Table columns={columns} dataSource={employees}/>
    )
}
