import React, { useState, useEffect } from 'react'
import { Form, Input, Modal, Select } from 'antd';
import axios from '../../../utils/clientAxios';

const layout = {
    labelCol: {
        xs: { span: 4 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 20 },
        sm: { span: 20 },
    },
};
export default function OrganizationMemberModal({ onSubmitHandler, visible, setVisible }) {
    const [form] = Form.useForm();
    const [roles, setRoles] = useState([]);

    useEffect(()=>{
        axios.get(`core/choices?model=users`)
            .then(response=>{
                setRoles(response.data?.users.user_roles || []);
            })
            .catch(err=>{

            })
    }, []);

    const onCancelHandler = e => {
        setVisible(false);
    }

    return (
        <Modal
            title="Add Member"
            visible={visible}
            onOk={(e) => {
                form.submit();
            }}
            onCancel={onCancelHandler}
        >
            <Form
                {...layout}
                form={form}
                onFinish={onSubmitHandler}
            >
                <Form.Item
                    label="First Name"
                    name="first_name"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="last_name"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Role"
                    name="organization_role"
                >
                <Select>
                    {Object.keys(roles).map((item, index) => {
                        return <Select.Option key={index} value={item}>{roles[item]}</Select.Option>
                    })}
                </Select>
                </Form.Item>
            </Form>
        </Modal>)
}
