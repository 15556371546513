import React, { useState, useEffect } from 'react'
import { Layout, Table, Button, Row, Col, Spin, Space, Popconfirm, message } from 'antd'
import { LoadingOutlined, UserAddOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import styles from '../../../../utils/GlobalStyles.module.scss';
import axios from '../../../../utils/clientAxios';
import { navigate, Link } from '@reach/router';
import { alphabeticOrderSorter, dateSorter } from '../../../../utils/utils';
import { phoneDisplay } from '../../../../utils/dataDisplay';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


export default function PeopleList() {
    const [people, setPeople] = useState(false);

    const fetchPeople = () => {
        axios.get(`persons/`)
            .then(response => {
                setPeople(response.data)
            })
            .catch(err => { })
    }
    
    useEffect(() => {
        fetchPeople();
    }, [])
    
    const deletePerson = id => {
        axios.delete(`persons/${id}/`)
            .then(response=>{
                message.success("Successfully deleted");
                fetchPeople();
            })
            .catch(err=>{

            })
    }
    const columns = [
        {
            title: "First Name",
            dataIndex: "first_name",
            render: (text, record) => {
                return (
                    <Link to={`/people/${record.id}`}>{record.first_name}</Link>
                )
            },
            // defaultSortOrder: 'ascend',
            sorter: (a, b) => alphabeticOrderSorter(a.first_name, b.first_name),
        },
        {
            title: "Last Name",
            dataIndex: "last_name",
            // defaultSortOrder: 'descend',
            sorter: (a, b) => alphabeticOrderSorter(a.last_name, b.last_name),
        },
        {
            title: "Email Address",
            dataIndex: "email_address"
        },
        {
            title: "Primary Phone",
            dataIndex: "primary_phone_number",
            render: (text, record) => phoneDisplay(text)
        },
        {
            title: "Secondary Phone",
            dataIndex: "secondary_phone_number",
            render: (text, record) => phoneDisplay(text)
        },
        {
            title: "Actions",
            dataIndex: "id",
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <EditTwoTone onClick={e => { navigate(`/people/${record.id}/edit`) }} />
                        <Popconfirm
                            title="Are you sure you want to delete this record?"
                            onConfirm={e=>{ deletePerson(record.id)}} 
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteTwoTone twoToneColor="#f5222d" />
                        </Popconfirm>
                    </Space>
                )
            }
        }
    ]
    
    return (
        <Layout className={styles.layout}>
            <Layout.Header>
                <Row justify="space-between" align="middle">
                    <h1 style={{ color: "white" }}>People</h1>
                    <Button type="primary" onClick={e => { navigate('/people/new') }} icon={<UserAddOutlined />}>Add Person</Button>
                </Row>
            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                {people ?
                    <Table columns={columns} dataSource={people} />
                    :
                    <Spin indicator={antIcon} />
                }
            </Layout.Content>
        </Layout>
    )
}
