import React, { useState, useEffect } from 'react'
import { Layout, message } from 'antd';
import TrademarkForm from '../../../../components/forms/TrademarkForm/TrademarkForm'
import styles from '../../../../utils/GlobalStyles.module.scss';
import axios from '../../../../utils/clientAxios';

export default function TrademarkEdit({ slug }) {
    const [trademark, setTrademark] = useState(false);
    useEffect(() => {
        axios.get(`trademarks/${slug}`)
            .then(response => {
                setTrademark(response.data);
            })
            .catch(err => {

            })
    }, [])
    const onSubmitHandler = data => {
        data.application_date = data.application_date.format('YYYY-MM-DD');
        data.office_actions_date = data.office_actions_date.format('YYYY-MM-DD');
        data.registration_date = data.registration_date.format('YYYY-MM-DD');
        data.renewal_date = data.renewal_date.format('YYYY-MM-DD');
        data.response_date = data.response_date.format('YYYY-MM-DD');
        data.publication_start_date = data.publication_start_date.format('YYYY-MM-DD');
        data.publication_end_date = data.publication_end_date.format('YYYY-MM-DD');
        data.notice_of_allowance = data.notice_of_allowance.format('YYYY-MM-DD');
        data.extension_due_date = data.extension_due_date.format('YYYY-MM-DD');

        axios.put(`trademarks/${trademark.id}/`, data)
            .then(response => {
                message.success("Successfully updated");
            })
            .catch(err => {
                message.error("Unable to update");
            })
    }
    return (
        <>
            {trademark &&
                <Layout className={styles.layout}>
                    <Layout.Header>
                        <h1 style={{ color: "white" }}>Edit Trademark</h1>
                    </Layout.Header>
                    <Layout.Content className={styles.layout_content}>
                        <TrademarkForm trademark={trademark} onSubmitHandler={onSubmitHandler} />
                    </Layout.Content>
                </Layout>
            }
        </>
    )
}
