import React, { useState, useEffect } from 'react';
import { Layout, message } from 'antd';
import styles from '../../../../utils/GlobalStyles.module.scss';
import CopyrightForm from '../../../../components/forms/CopyrightForm/CopyrightForm';
import axios from '../../../../utils/clientAxios';

export default function CopyrightEdit({ slug }) {
    const [copyright, setCopyright] = useState(false);

    const onSubmitHandler = data => {
        // data.first_publication_date = data.first_publication_date.format('YYYY-MM-DD');
        // data.issue_date = data.issue_date.format('YYYY-MM-DD');
        // data.expiration_date = data.expiration_date.format('YYYY-MM-DD');
        axios.put(`copyrights/${copyright.id}/`, data)
            .then(response => {
                message.success("Successfully updated");
            })
            .catch(err => {
                message.error("Unable to update");
            })

    }

    useEffect(() => {
        axios.get(`copyrights/${slug}/`)
            .then((response) => {
                setCopyright(response.data);
            })
            .catch(() => {

            })
    }, [])

    return (
        <>
            {
                copyright &&
                <Layout className={styles.layout}>
                    <Layout.Header>
                        <h1 style={{ color: "white" }}>Add Copyright</h1>
                    </Layout.Header>
                    <Layout.Content className={styles.layout_content}>
                        <CopyrightForm onSubmitHandler={onSubmitHandler} copyright={copyright} />
                    </Layout.Content>
                </Layout>
            }
        </>
    )
}
