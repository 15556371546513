import { EditOutlined } from '@ant-design/icons';
import { Button, Descriptions, Layout, Row } from 'antd';
import React, {useEffect, useState} from 'react';
import InvoiceDetailHeader from '../../../../components/InvoiceDetailHeader/InvoiceDetailHeader';
import InvoiceSendModal from '../../../../components/modals/InvoiceSendModal/InvoiceSendModal';
import InvoiceLineItemTable from '../../../../components/tables/InvoiceLineItemTable/InvoiceLineItemTable';
import axios from '../../../../utils/clientAxios';
import styles from '../../../../utils/GlobalStyles.module.scss';


export default function InvoiceDetail({slug}) {
    const [invoice, setInvoice] = useState(false);

    useEffect(() => {
        axios.get(`invoices/${slug}`)
            .then(response=>{
                console.log(response.data)
                setInvoice(response.data);
            })
            .catch(err=>{

            })
    }, [])

    return (
        <>
            {invoice && <Layout className={styles.layout}>
                <Layout.Header>
                    <Row justify="space-between" align='middle'>
                        <h1 style={{ color: "white" }}>Invoice: {invoice?.number}</h1>
                        <Button href={`/invoices/${slug}/edit`} type="primary" icon={<EditOutlined/>}>Edit</Button>
                    </Row>
                </Layout.Header>
                <Layout.Content className={styles.layout_content}>
                    <Descriptions title={<InvoiceDetailHeader invoice={invoice}/>}>
                        <Descriptions.Item label="Date of Invoice"></Descriptions.Item>
                        <Descriptions.Item label="Number">{`${invoice?.number}`}</Descriptions.Item>
                        <Descriptions.Item label="Contact Person">{`${invoice?.contact_person?.last_name}, ${invoice?.contact_person?.first_name}`}</Descriptions.Item>
                        <Descriptions.Item label="Initiated By">{`${invoice?.initiated_by?.last_name}, ${invoice?.initiated_by?.first_name}`}</Descriptions.Item>
                        <Descriptions.Item label="Status">{`${invoice?.status}`}</Descriptions.Item>
                        <Descriptions.Item label="Invoice Total">{`$${invoice?.total}`}</Descriptions.Item>
                    </Descriptions>
                    <InvoiceLineItemTable lineItems={invoice?.line_items}/>
                </Layout.Content>
            </Layout>}
        </>
    )
}
