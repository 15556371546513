import React, { useState, useEffect } from 'react'
import axios from '../../../../utils/clientAxios';
import { ModelMapping } from '../../../../utils/utils';
import styles from '../../../../utils/GlobalStyles.module.scss';
import NotesDisplayTable from '../../../../components/Display/NotesDisplayTable';
import FileComponent from '../../../../components/Display/FileComponent/FileComponent';
import { Layout, Row, Col, Descriptions, Button, Tabs, Typography } from 'antd';
import { navigate } from '@reach/router';
import { EditOutlined, PlusCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import NewNoteModal from '../../../../components/modals/NewNoteModal/NewNoteModal';
import CopyrightStatusTag from '../../../../components/CopyrightStatusTag/CopyrightStatusTag';

export default function CopyrightDetail({ slug }) {
    const [copyright, setCopyright] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);

    useEffect(() => {
        axios.get(`copyrights/${slug}`)
            .then(response => {
                setCopyright(response.data);
            })
            .catch(() => {

            })
    }, []);

    const onSubmitNoteHandler = (e, text) => {
        axios.post(`notes/`, {
            object_id: copyright.id,
            model_name: 'copyright',
            text: text
        });
    }
    return (
        <>
            {
                copyright &&
                <>
                    <Layout className={styles.layout}>
                        <Layout.Header>
                            <Row justify="space-between" align="middle">
                                <h1 style={{ color: "white" }}>{copyright.title}</h1>
                                <Button type="primary" onClick={() => navigate(`/copyrights/${slug}/edit/`)} icon={<EditOutlined />}>Edit</Button>
                            </Row>
                        </Layout.Header>
                        <Layout.Content className={styles.layout_content}>
                            <Tabs defaultActiveKey="1">
                                <Tabs.TabPane tab="Copyright Information" key="1">
                                    <Row>
                                        <Col>
                                            <Descriptions title="Copyright Info">
                                                <Descriptions.Item label="Product">{copyright.product}</Descriptions.Item>
                                                <Descriptions.Item label="Title">{copyright.title}</Descriptions.Item>
                                                <Descriptions.Item label="Status"><CopyrightStatusTag status={copyright.status} /></Descriptions.Item>
                                                <Descriptions.Item label="Jurisdiction">{copyright.jurisdiction}</Descriptions.Item>
                                                <Descriptions.Item label="Number">{copyright.number}</Descriptions.Item>
                                                <Descriptions.Item label="First Publication Date">{copyright.first_publication_date}</Descriptions.Item>
                                                <Descriptions.Item label="Issue Date">{copyright.issue_date}</Descriptions.Item>
                                                <Descriptions.Item label="Expiration Date">{copyright.expiration}</Descriptions.Item>
                                                <Descriptions.Item label="Instance Owner">{copyright.product}</Descriptions.Item>
                                                <Descriptions.Item label="Is Made for Hire">{copyright.is_made_for_hire ? <CheckCircleTwoTone twoToneColor="green" /> : <CloseCircleTwoTone twoToneColor="red" />}</Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Notes" key="3">
                                    <Row justify="space-between">
                                        <Typography.Title level={3}>Notes</Typography.Title>
                                        <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => setVisibleModal(true)}>Add Note</Button>
                                    </Row>
                                    <NotesDisplayTable model={ModelMapping.copyright} objectId={copyright.id} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Files" key="2">
                                    <FileComponent model={ModelMapping.copyright} objectId={copyright.id} />
                                </Tabs.TabPane>
                            </Tabs>
                        </Layout.Content>
                    </Layout>
                    <NewNoteModal
                        visible={visibleModal}
                        setVisible={setVisibleModal}
                        onSubmitHandler={onSubmitNoteHandler}
                    />
                </>
            }
        </>
    )
}
