import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { Layout, Menu, Row, Col, Typography, Button, Space, Form, Input } from 'antd';
import { CheckCircleOutlined, CopyTwoTone, FileTwoTone, CopyrightCircleTwoTone, BankTwoTone, ContactsTwoTone } from '@ant-design/icons';

import styles from './LandingPage.module.scss';
import { layout, tailFormItemLayout } from '../../../utils/layouts';

export default function LandingPage() {
    const [contactForm] = Form.useForm();

    const features = [
        'Intellectual Property',
        'Contract Management',
        'Corporate Compliance',
        'HR Security',
        'IT Contact List',
        'Template Creation'
    ]
    return (
        <Layout>
            <Layout.Header className={styles.navbar}>
                <Menu className={styles.navbar_menu} theme="light" mode="horizontal">
                    <Menu.Item>Proxint</Menu.Item>
                    <Menu.Item>Login</Menu.Item>
                    <Menu.Item>Sign Up</Menu.Item>
                </Menu>
            </Layout.Header>
            <Layout.Content>
                <Row>
                    <Col span={14} className={styles.left_jumbotron}>
                        <Typography.Title className={styles.title}>Proxint</Typography.Title>
                        <p className={styles.about}>
                            A program designed to bridge the gaps within your company. Access all documents, IP, deadlines, and contacts within one source.
                        </p>
                        <div>
                            {features.map((item, index)=>{
                                return(
                                    <div className={styles.list_item}>
                                        <Space>
                                        <CheckCircleOutlined />
                                        <span>{item}</span>                        
                                        </Space>
                                    </div>
                                )
                            })}
                        </div>
                        <Space>
                            <Button type="primary" onClick={()=>{navigate('/login')}}>Login</Button>
                            <Button type="primary" onClick={()=>{navigate('/register')}} >Register</Button>
                        </Space>
                    </Col>
                    <Col span={10} className={styles.image}>
                        
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={4}>
                        <div className={styles.card}>
                            <div className={styles.header}>
                                Intellectual Property
                            </div>
                            <div className={styles.icon}>
                                <CopyrightCircleTwoTone />
                            </div>
                            <div className={styles.description}>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Necessitatibus natus et quae, officiis optio reprehenderit, ut ex pariatur error consectetur animi beatae. Ab quis quidem suscipit voluptatum consequatur hic commodi!
                            </div>
                        </div>   
                    </Col>
                    <Col span={4}>
                        <div className={styles.card}>
                            <div className={styles.header}>
                                Template Creation                                
                            </div>
                            <div className={styles.icon}>
                                <CopyTwoTone />
                            </div>
                            <div className={styles.description}>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Necessitatibus natus et quae, officiis optio reprehenderit, ut ex pariatur error consectetur animi beatae. Ab quis quidem suscipit voluptatum consequatur hic commodi!                                
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className={styles.card}>
                            <div className={styles.header}>
                                Contract Management                            
                            </div>
                            <div className={styles.icon}>
                                <FileTwoTone />
                            </div>
                            <div className={styles.description}>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Necessitatibus natus et quae, officiis optio reprehenderit, ut ex pariatur error consectetur animi beatae. Ab quis quidem suscipit voluptatum consequatur hic commodi!                                                                
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={4}>
                        <div className={styles.card}>
                            <div className={styles.header}>
                                Corporate Compliance
                            </div>
                            <div className={styles.icon}>
                                <BankTwoTone />
                            </div>
                            <div className={styles.description}>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Necessitatibus natus et quae, officiis optio reprehenderit, ut ex pariatur error consectetur animi beatae. Ab quis quidem suscipit voluptatum consequatur hic commodi!
                            </div>
                        </div>   
                    </Col>
                    <Col span={4}>
                        <div className={styles.card}>
                            <div className={styles.header}>
                                Contact Lst                            
                            </div>
                            <div className={styles.icon}>
                                <ContactsTwoTone />
                            </div>
                            <div className={styles.description}>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Necessitatibus natus et quae, officiis optio reprehenderit, ut ex pariatur error consectetur animi beatae. Ab quis quidem suscipit voluptatum consequatur hic commodi!
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className={styles.card}>
                            <div className={styles.header}>
                                Intellectual Property                        
                            </div>
                            <div className={styles.icon}>
                                <FileTwoTone />
                            </div>
                            <div className={styles.description}>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Necessitatibus natus et quae, officiis optio reprehenderit, ut ex pariatur error consectetur animi beatae. Ab quis quidem suscipit voluptatum consequatur hic commodi!                            
                            </div>
                        </div>
                    </Col>
                </Row>
                <section className={styles.contact_section}>
                    <Row align="center">
                        <Col span={6}>
                            <Typography.Title className={styles.header}>Contact</Typography.Title>
                            <Form
                                form={contactForm}
                            >
                                <Form.Item>
                                    <Input size="large" placeholder="Your Email" />
                                </Form.Item>
                                <Form.Item>
                                    <Input size="large" placeholder="Subject" />
                                </Form.Item>
                                <Form.Item>
                                    <Input.TextArea size="large" rows={5} placeholder="Question" />
                                </Form.Item>
                                <Button type="primary" block htmlType="submit">Send</Button>
                            </Form>
                        </Col>
                    </Row>
                </section>
            </Layout.Content>
            <Layout.Footer>
                <Row justify="center">
                    &#169; 2020 Proxint
                </Row>
            </Layout.Footer>
        </Layout>
    )
}
