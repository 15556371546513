import React, { useState, useEffect } from 'react'
import { Layout, Table, Button, Row, Col, Spin, Space, Modal, Popconfirm } from 'antd'
import { LoadingOutlined, PlusCircleOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import axios from '../../../../utils/clientAxios';
import { navigate, Link } from '@reach/router';
import styles from '../../../../utils/GlobalStyles.module.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;



export default function PatentList() {
    const [patents, setPatents] = useState(false);

    const deletePatent = id => {
        axios.delete(`patents/${id}/`)
            .then(response=>{

            })
            .catch(err=>{

            })
    }

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            render: (text, record) => {
                return (
                    <Link to={`/patents/${record.id}`}>{record.title}</Link>
                )
            }
        },
        {
            title: "Product",
            dataIndex: "product"
        },
        {
            title: "Status",
            dataIndex: "status"
        },
        {
            title: "jurisdiction",
            dataIndex: "jurisdiction"
        },
        {
            title: "Number",
            dataIndex: "number"
        },
        {
            title: "Expiration Date",
            dataIndex: "expiration_date"
        },
        {
            title: "Actions",
            dataIndex: "id",
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <EditTwoTone onClick={e => { navigate(`/patents/${record.id}/edit`) }} />
                        <Popconfirm
                            placement="rightTop"
                            title={`Are you sure you want to delete?`}
                            onConfirm={()=>deletePatent(record.id)}
                            okText="Yes"
                            cancelText="No"                                        
                        >
                            <DeleteTwoTone twoToneColor="#f5222d" />
                        </Popconfirm>
                    </Space>
                )
            }
        }
    ]
    useEffect(() => {
        axios.get(`patents/`)
            .then(response => {
                setPatents(response.data)
            })
            .catch(err => { })
    }, [])
    return (
        <Layout className={styles.layout}>
            <Layout.Header>
                <Row justify="space-between" align="middle">
                    <h1 style={{ color: "white" }}>Patents</h1>
                    <Button type="primary" onClick={e => { navigate('/patents/new') }} icon={<PlusCircleOutlined />}>Add Patent</Button>
                </Row>
            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                {patents ?
                    <Table columns={columns} dataSource={patents} />
                    :
                    <Spin indicator={antIcon} />
                }
            </Layout.Content>
        </Layout>
    )
}

