import React, {useState, useEffect} from 'react'
import { Button, Form, Input, Row, Select, Space, Col, Typography, DatePicker } from 'antd';
import { layout, tailFormItemLayout } from '../../../utils/layouts';
import axios from '../../../utils/clientAxios';
import PersonSelect from '../../FormInputs/PersonSelect/PersonSelect';
import StateDropdownInput from '../../FormInputs/StateDropdownInput/StateDropdownInput';
import CompanyEntityTypesInput from '../../FormInputs/CompanyEntityTypesInput/CompanyEntityTypesInput';
import CompanyStatusInput from '../../FormInputs/CompanyStatusInput/CompanyStatusInput';
import AnnualFilingFeeStatusInput from '../../FormInputs/AnnualFilingFeeStatusInput/AnnualFilingFeeStatusInput';
import { navigate, useLocation } from '@reach/router';
import RegisteredAgentStatusInput from '../../FormInputs/RegisteredAgentStatusInput/RegisteredAgentStatusInput';
import { displayDateOrReturnNull } from '../../../utils/utils';
import CancelFormButton from '../../buttons/CancelFormButton/CancelFormButton';

export default function CompanyForm({onSubmitHandler, company}) {
    const [form] = Form.useForm();

    const handleSubmit = data => {
        console.log(data)
        const transformedData = {
            ...data,
            registered_agent_fee_due_date: data.registered_agent_fee_due_date.format('YYYY-MM-DD'),
            annual_filing_fee_due_date: data.annual_filing_fee_due_date.format('YYYY-MM-DD'),
            date_of_incorporation: data.date_of_incorporation.format('YYYY-MM-DD'),
        }
        onSubmitHandler(transformedData);
    }

    return (
        <Form
            {...layout}
            form={form}
            onFinish={handleSubmit}
        >
            <Form.Item
                label="Name"
                name="name"
                initialValue={company?.name}
                defaultValue={company?.name}
            >
                <Input />
            </Form.Item>
            <CompanyEntityTypesInput fieldName="entity_type" form={form} defaultValue={company?.entity_type} label="Entity Type" />
            <CompanyStatusInput fieldName="status" form={form} defaultValue={company?.status} label="Status"/>
            <Form.Item
                label="EIN"
                name="ein"
                initialValue={company?.ein}
            >
                <Input/>
            </Form.Item>
            <StateDropdownInput fieldName="state_of_incorporation" form={form} defaultValue={company?.state_of_incorporation} label="State of Incorporation" />
            <Form.Item
                label="Date of Incorporation"
                name="date_of_incorporation"
                initialValue={displayDateOrReturnNull(company?.date_of_incorporation)}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                label="Entity Number"
                name="entity_number"
                initialValue={company?.entity_number}
            >
                <Input />
            </Form.Item>
            <PersonSelect fieldName="registered_agent" form={form} defaultValue={company?.registered_agent} label="Registered Agent" />
            <Form.Item
                label="Registered Agent Fee Due Date"
                name="registered_agent_fee_due_date"
                initialValue={displayDateOrReturnNull(company?.registered_agent_fee_due_date)}
            >
                <DatePicker />
            </Form.Item>
            <RegisteredAgentStatusInput fieldName="registered_agent_status" form={form} defaultValue={company?.registered_agent_status} label="Registered Agent Status" />
            <AnnualFilingFeeStatusInput fieldName="annual_filing_status" form={form} defaultValue={company?.annual_filing_status} label="Annual Filing Fee Status"/>
            <Form.Item
                label="Annual Filing Fee Due Date"
                name="annual_filing_fee_due_date"
                initialValue={displayDateOrReturnNull(company?.annual_filing_fee_due_date)}
            >
                <DatePicker />
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
                <Space size="middle">
                    <Button type="primary" htmlType="submit">Save</Button>
                    <CancelFormButton></CancelFormButton>
                    <Button type="danger" onClick={()=>navigate(document.referrer ? document.referrer : '/companies')}>Cancel</Button>
                </Space>
            </Form.Item>
        </Form>
    )
}
