import React from 'react';
import { Layout, Form, Input, Button, Space, DatePicker, message } from 'antd';
import { layout, tailFormItemLayout } from '../../../../utils/layouts';
import styles from '../../../../utils/GlobalStyles.module.scss';
import axios from '../../../../utils/clientAxios';
import PatentForm from '../../../../components/forms/PatentForm/PatentForm';

export default function PatentCreate() {
    const [form] = Form.useForm();
    const onSubmitHandler = data => {
        console.log(data);
        data.filing_date = data.filing_date.format('YYYY-MM-DD');
        data.priority_date = data.priority_date.format('YYYY-MM-DD');
        data.issue_date = data.issue_date.format('YYYY-MM-DD');
        data.renewal_fee_date = data.renewal_fee_date.format('YYYY-MM-DD');
        data.expiration_date = data.expiration_date.format('YYYY-MM-DD');
        axios.post(`patents/`,
            data)
            .then(response => {
                message.success(response.data.name);
            })
            .catch(err => {
                message.error();
            })
    }
    return (
        <Layout>
            <Layout.Header>
                <h1 style={{ color: "white" }}>Add Patent</h1>
            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                <PatentForm onSubmitHandler={onSubmitHandler} patent={{}} />
            </Layout.Content>
        </Layout>

    )
}
