import React from 'react'
import { Tag } from 'antd';
import { trademarkChoices } from '../../utils/choiceMapping';

export default function TrademarkStatusTag({ status }) {
    const colors = {
        pending: 'cyan',
        registered: 'darkgreen',
        expired: 'red',
        abandoned: 'grey',
        other: 'purple'
    }

    return (
        <Tag color={colors[status]}>
            {trademarkChoices.statusMapping[status]}
        </Tag>
    )
}
