import React, { useState, useEffect } from 'react'
import { Form, Select } from 'antd';
import axios from '../../../utils/clientAxios';

const { Option } = Select;

export default function CompanyInput({ fieldName, form, defaultValue, label }) {
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        if(defaultValue) {
            axios.get(`companies/`)
                .then(response=>{
                    if(defaultValue){
                        console.log("TEST")
                        console.log([defaultValue, ...response.data])
                        setCompanies([defaultValue, ...response.data])
                    }
                    else{
                        console.log(response.data)
                        setCompanies(response.data);
                    }
                })
            }
        }, [])
        
        const onChange = e => {
            form.setFieldsValue({ [fieldName]: e });
        }
        
        const onSearch = data => {
            axios.get(`companies/?search=${data}`)
            .then((response) => {
                setCompanies(response.data.slice(0, 5))
            })       
        }
        const getDisplayValue = person => {
            
        }
        // const options = 
        
        return (
            <Form.Item
                label={label}
                name={fieldName}
                initialValue={(defaultValue?.id)}
            >
            <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select a company"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={false}
                notFoundContent={null}
                defaultValue={(defaultValue?.id)}
                >
                    <>
                    {   companies.length > 0 &&
                        companies.map((item, index) => {
                            return <Option key={index} value={item.id}>{`${item.name}`}</Option>
                        })                        
                    }
                    </>
            </Select>
        </Form.Item>
    )
}
