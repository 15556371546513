import { Layout } from 'antd'
import React from 'react'
import EmployeeForm from '../../../../components/forms/EmployeeForm/EmployeeForm'
import axios from '../../../../utils/clientAxios';
import styles from '../../../../utils/GlobalStyles.module.scss';

export default function EmployeeCreate() {
    const onSubmitHandler = data => {
        axios.post('employees/', data)
            .then(response=>{

            })
            .catch(err=>{

            })
    }
    return (
        <Layout className={styles.layout}>
            <Layout.Header>
                New Employee
            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                <EmployeeForm onSubmitHandler={onSubmitHandler} employee={{}}/>
            </Layout.Content>
        </Layout>
    )
}
