import React, { useState } from 'react'
import { Form, Layout, Input, Button, Space, Alert, message } from 'antd'
import axios from '../../../../utils/clientAxios';
import styles from '../../../../utils/GlobalStyles.module.scss';
import PeopleForm from '../../../../components/forms/PeopleForm/PeopleForm';

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 10 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 8,
            offset: 4,
        },
    },
};
const success = (name) => {
    message.success(`${name} has been added`);
};
const error = () => {
    message.error("This person could not be added. Try again")
}
export default function PeopleCreate() {
    const [form] = Form.useForm();
    const onSubmitHandler = data => {
        axios.post(`persons/`,
            data)
            .then(response => {
                message.success(`${response.data.first_name} ${response.data.last_name} has been added`);
            })
            .catch(err => {
                error();
            })
    }
    return (
        <Layout className={styles.layout}>
            <Layout.Header>
                <h1 style={{ color: "white" }}>Add Person</h1>
            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                <PeopleForm onSubmitHandler={onSubmitHandler} />
            </Layout.Content>
        </Layout>

    )
}
