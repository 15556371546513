import React from 'react'
import { Tag } from 'antd';
import { copyrightChoices } from '../../utils/choiceMapping';

export default function CopyrightStatusTag({ status }) {
    const colors = {
        pending: 'cyan',
        registered: 'darkgreen',
    }

    return (
        <Tag color={colors[status]}>
            {copyrightChoices.statusMapping[status]}
        </Tag>
    )
}
