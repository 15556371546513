import { Link } from '@reach/router';
import { Table } from 'antd';
import React, {useState, useEffect} from 'react';
import axios from '../../../utils/clientAxios';

export default function InvoiceListTable() {
    const [invoices, setInvoices] = useState([])
    useEffect(()=>{
        axios.get('invoices/')
            .then(response=>{
                setInvoices(response.data);
            })
            .catch(err=>{
                
            })
    }, [])

    const columns = [
        {
            title: "Order Number",
            render: (text, record) => {
                return <Link to={`/invoices/${record.id}`}>{record.number}</Link>
            }
        },
        {
            title: "Status",
            dataIndex: "status"
        },
        {
            title: "Contact Person",
            dataIndex: "contact_person"
        },
        {
            title: "Initiated By",
            render: (text, record) => {
                return `${record.last_name}, ${record.first_name}`
            }
        },
        {
            title: "Date of Invoice",
            dataIndex: "date_of_invoice"
        }
    ]

    return (
        <Table columns={columns} dataSource={invoices}/>
    )
}
