import { Select, Form } from 'antd';
import React, {useEffect, useState} from 'react'
import axios from '../../../utils/clientAxios';

export default function CompanyStatusInput({fieldName, form, defaultValue, label}) {
    const [statuses, setStatuses] = useState([]);
    
    useEffect(() => {
        axios.get('core/choices?model=companies')
            .then(response=>{
                setStatuses(response.data.companies.statuses);
            })
            .catch(err=>{

            })
        
    }, []);

    const onChange = e => {
        form.setFieldsValue({ [fieldName]: e });
    }

    return (
        <Form.Item
            label={label}
            name={fieldName}
            initialValue={defaultValue}
        >
            <Select
                onChange={onChange}
            >
                {Object.keys(statuses).map((key, index)=>{
                    return <Select.Option value={key}>{statuses[key]}</Select.Option>
                })}
            </Select>
        </Form.Item>
    )
}
