import React from 'react'
import { Form, Input, Switch, Space, Button, DatePicker } from 'antd';
import axios from '../../../utils/clientAxios';
import { layout, tailFormItemLayout } from '../../../utils/layouts';
import styles from './EmployeeForm.module.scss';
import { displayDateOrToday } from '../../../utils/utils';

export default function EmployeeForm({onSubmitHandler, employee}) {
    const [form] = Form.useForm();

    const transformedOnSubmitHandler = data => {
        console.log(data)
        const transformedData = {
            ...data,
            dob: data.dob.format('YYYY-MM-DD'),
            hire_date: data.hire_date.format('YYYY-MM-DD'),
            termination_date: (data && data.termination_date.format('YYYY-MM-DD')),
            person: {
                first_name: data.first_name,
                last_name: data.last_name,
                email_address: data.email_address,
                primary_phone_number: data.primary_phone_number,
                secondary_phone_number: data.secondary_phone_number,
                job_title: data.job_title,
                address_line_1: data.address_line_1,
                address_line_2: data.address_line_2,
                city: data.city,
                state: data.state,
                zip_code: data.zip_code
            }
        }
        onSubmitHandler(transformedData);
    }

    return (
        <Form
            {...layout}
            form={form}
            onFinish={transformedOnSubmitHandler}
        >
            <Form.Item
                label="First Name"
                name="first_name"
                rules={[{ required: true, message: "This field is required" }]}
                initialValue={employee?.first_name}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Last Name"
                name="last_name"
                rules={[{ required: true, message: "This field is required" }]}
                initialValue={employee?.last_name}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Email"
                name="email_address"
                initialValue={employee?.email_address}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Primary Phone"
                name="primary_phone_number"
                initialValue={employee?.primary_phone_number}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Secondary Phone"
                name="secondary_phone_number"
                initialValue={employee?.secondary_phone_number}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Job Title"
                name="job_title"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Address Line 1"
                name="address_line_1"            
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Address Line 2"
                name="address_line_2"            
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="City"
                name="city"       
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="State"
                name="state"            
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Zip Code"
                name="zip_code"            
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Date of Birth"
                name="dob"     
                initialValue={displayDateOrToday(employee?.application_date)}       
            >
                <DatePicker/>
            </Form.Item>
            <Form.Item
                label="Emergency Contact Name"
                name="emergency_contact_name"            
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Emergency Contact Phone"
                name="emergency_contact_phone_number"            
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Wage"
                name="wage"            
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Wage Type"
                name="wage_type"            
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Hire Date"
                name="hire_date"            
                initialValue={displayDateOrToday(employee?.hire_date)}       
            >
                <DatePicker/>
            </Form.Item>
            <Form.Item
                label="Termination Date"
                name="termination_date"            
                initialValue={displayDateOrToday(employee?.termination_date)}       
            >
                <DatePicker/>
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
                <Space size="middle">
                    <Button type="primary" htmlType="submit">Submit</Button>
                    <Button type="primary" danger>Cancel</Button>
                </Space>
            </Form.Item>
        </Form>
    )
}
