import { Layout, Row, Button, Tabs, Descriptions, Typography, Col } from 'antd';
import { EditOutlined, PlusCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import React, { useState, useEffect } from 'react'
import axios from '../../../../utils/clientAxios'
import { navigate } from '@reach/router'
import styles from '../../../../utils/GlobalStyles.module.scss';
import FileComponent from '../../../../components/Display/FileComponent/FileComponent';
import NotesDisplayTable from '../../../../components/Display/NotesDisplayTable';
import { ModelMapping } from '../../../../utils/utils';
import NewNoteModal from '../../../../components/modals/NewNoteModal/NewNoteModal';

export default function CompanyDetail({slug}) {
    const [company, setCompany] = useState();
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(()=>{
        axios.get(`companies/${slug}/`)
            .then(response=>{
                setCompany(response.data);  
            })
            .catch(err=>{

            })
    }, [])
    const onSubmitNoteHandler = (e, text) => {
        axios.post(`notes/`, {
            object_id: company.id,
            model_name: 'company',
            text: text
        });
    }
    return (
        <>
            {
                company && 
                <>
                    <Layout className={styles.layout}>
                        <Layout.Header>
                            <Row justify="space-between" align="middle">
                                <h1 style={{ color: "white" }}>{company.name}</h1>
                                <Button type="primary" onClick={() => navigate(`/companies/${slug}/edit/`)} icon={<EditOutlined />}>Edit</Button>
                            </Row>
                        </Layout.Header>
                        <Layout.Content className={styles.layout_content}>
                            <Tabs defaultActiveKey="1">
                                <Tabs.TabPane tab="Company Information" key="1">
                                    <Row>
                                        <Col>
                                            <Descriptions title="Company Info">
                                                <Descriptions.Item label="Name">{company.name}</Descriptions.Item>
                                                <Descriptions.Item label="Entity Type">{company.entity_type}</Descriptions.Item>
                                                <Descriptions.Item label="Status">{company.status}</Descriptions.Item>
                                                <Descriptions.Item label="State of Incorporation">{company.state_of_incorporation}</Descriptions.Item>
                                                <Descriptions.Item label="Date of Incorporation">{company.date_of_incorporation}</Descriptions.Item>
                                                <Descriptions.Item label="Entity Number">{company.entity_number}</Descriptions.Item>
                                                <Descriptions.Item label="EIN">{company.ein}</Descriptions.Item>
                                                <Descriptions.Item label="Registered Agent">{company.registered_agent}</Descriptions.Item>
                                                <Descriptions.Item label="Registered Agent Fee Due Date">{company.registered_agent_fee_due_date}</Descriptions.Item>
                                                <Descriptions.Item label="Registered Agent Status">{company.registered_agent_status}</Descriptions.Item>
                                                <Descriptions.Item label="Annual Filing Fee Due Date">{company.annual_filing_fee_due_date}</Descriptions.Item>
                                                <Descriptions.Item label="Annual Filing Status">{company.annual_filing_status}</Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Notes" key="3">
                                    <Row justify="space-between">
                                        <Typography.Title level={3}>Notes</Typography.Title>
                                        <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => setModalVisible(true)}>Add Note</Button>
                                    </Row>
                                    <NotesDisplayTable model={ModelMapping.company} objectId={company.id} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Files" key="2">
                                    <FileComponent model={ModelMapping.company} objectId={company.id} />
                                </Tabs.TabPane>
                            </Tabs>
                        </Layout.Content>
                    </Layout>
                    <NewNoteModal
                        visible={modalVisible}
                        setVisible={setModalVisible}
                        onSubmitHandler={onSubmitNoteHandler}
                    />
                </>

            }
        </>
)
}
