import React, { useState, useEffect } from 'react';
import styles from '../../../../utils/GlobalStyles.module.scss';
import CompanyForm from '../../../../components/forms/CompanyForm/CompanyForm';
import axios from '../../../../utils/clientAxios';
import { Layout, message } from 'antd';
import { navigate } from '@reach/router';

export default function CompanyEdit({slug}) {
    const [company, setCompany] = useState(false);
    
    useEffect(() => {
        axios.get(`companies/${slug}/`)
            .then(response=>{
                setCompany(response.data);
            })
            .catch(err=>{

            })
    }, [])
    
    const onSubmitHandler = data => {
        axios.put(`companies/${slug}/`, data)
            .then(response=>{
                message.success("Updated Successfully!");
                navigate(`/companies/${slug}/`)
            })
            .catch(err=>{
                message.error("There was an error.")                
            })
    }

    return (
        <Layout className={styles.layout}>
            <Layout.Header>

            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                {
                    company &&
                    <CompanyForm onSubmitHandler={onSubmitHandler} company={company} />
                }
            </Layout.Content>
        </Layout>
    )
}
