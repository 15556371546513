import React, { useState, useEffect } from 'react'
import { Form, Select } from 'antd';
import axios from '../../../utils/clientAxios';

const { Option } = Select;

export default function PersonSelect({ fieldName, form, defaultValue, label }) {
    const [people, setPeople] = useState([]);

    useEffect(() => {
        if(defaultValue) {
            axios.get(`persons/${defaultValue}`)
                .then(response=>{
                    setPeople([response.data]);
                })
                .catch(console.error)
            }
            else{
                axios.get(`persons/`)
                .then((response) => {
                    setPeople(response.data.slice(0, 5))
                })
            }
        }, [])
        
        const onChange = e => {
            form.setFieldsValue({ [fieldName]: e });
            console.log(e)
        }
        
        const onSearch = data => {
            axios.get(`persons/?search=${data}`)
            .then((response) => {
                setPeople(response.data.slice(0, 5))
            })       
        }
        const getDisplayValue = person => {
            
        }
        // const options = 
        
        return (
            <Form.Item
            label={label}
            name={fieldName}
            initialValue={defaultValue}
            >
            <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select a person"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={false}
                notFoundContent={null}
                defaultValue={defaultValue}
                >
                    <>
                    {   people.length > 0 &&
                        people.map((item, index) => {
                            return <Option key={index} value={item.id}>{`${item.last_name}, ${item.first_name}`}</Option>
                        })                        
                    }
                    </>
            </Select>
        </Form.Item>
    )
}
