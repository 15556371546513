import React, { useContext, useState } from 'react'
import { Grid, Layout, Row, Col, Form, Typography, Card, Input, Button, message, Steps } from 'antd'
import { MailOutlined, LockOutlined, BankOutlined } from '@ant-design/icons';
import { Link, navigate } from '@reach/router';
import styles from './RegisterView.module.scss';
import GlobalContext from '../../../../store/GlobalContext';
import axios from '../../../../utils/clientAxios';
import UserRegistrationForm from '../../../../components/forms/UserRegistrationForm/UserRegistrationForm';
import ChoosePlanForm from '../../../../components/forms/ChoosePlanForm/ChoosePlanForm';
import StripeContainer from '../../../../containers/StripeContainer/StripeContainer';

const titles = ['User Information', "Plan Details", "Billing Information"]

export default function RegisterView() {
    const [form] = Form.useForm()
    const [planForm] = Form.useForm();
    const { globalDispatch } = useContext(GlobalContext);
    const [currentStep, setCurrentStep] = useState(0);
    const [loader, setLoader] = useState();

    return (
        <Layout className={styles.login_layout}>
            <Row justify="center" align="middle" className={styles.row}>
                <Col>
                    <Row>
                        <Card title={titles[currentStep]} className={styles.login_card}>
                            {currentStep === 0 && <UserRegistrationForm setCurrentStep={setCurrentStep}/>}
                            {currentStep === 1 && <StripeContainer form={planForm} setCurrentStep={setCurrentStep} />}
                        </Card>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <Steps size="small" current={currentStep}>
                                <Steps.Step title="Create Account"/>
                                <Steps.Step title="Choose Plan"/>
                                <Steps.Step title="Payment Information"/>
                            </Steps>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Layout>
    )
}
