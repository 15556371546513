import React, { useState, useEffect } from 'react'
import { Layout, Table, Button, Row, Col, Spin, Space, Modal, Popconfirm, message, Tabs, Typography } from 'antd'
import { LoadingOutlined, PlusCircleOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import axios from '../../../../utils/clientAxios';
import { navigate, Link } from '@reach/router';
import styles from '../../../../utils/GlobalStyles.module.scss';
import CompanyTable from '../../../../components/tables/CompanyTable/CompanyTable';

export default function CompanyList() {
    const [companies, setCompanies] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedToDelete, setSelectedToDelete] = useState(0);

    const handleDelete = (e, id) => {
        axios.delete(`companies/${id}/`) 
            .then(response => {
                message.success("Company Deleted");
                setCompanies(companies.filter(company => company.id !== id))
            })
            .catch(err => {

            })
    }
    
    return (
        <Layout className={styles.layout}>
            <Modal
                title="Basic Modal"
                visible={showModal}
                onOk={handleDelete}
                onCancel={e => { setShowModal(false) }}
                okText="Delete"
                okButtonProps={{ danger: true }}
            >
                Are you sure you want to delete this Company?
            </Modal>
            <Layout.Header>
                <Row justify="space-between" align="middle">
                    <h1 style={{ color: "white" }}>Companies</h1>
                    <Button type="primary" onClick={e => { navigate('/companies/new') }} icon={<PlusCircleOutlined />}>Add Company</Button>
                </Row>
            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                <CompanyTable />
            </Layout.Content>
        </Layout>
    )
}

