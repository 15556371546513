import React, { useEffect, useState } from 'react'
import { Layout, Row, Descriptions, Col, Alert, Typography, Divider, Button, Tabs, Space } from 'antd'
import axios from '../../../../utils/clientAxios';
import styles from '../../../../utils/GlobalStyles.module.scss';
import { phoneDisplay } from '../../../../utils/dataDisplay';
import NotesDisplayTable from '../../../../components/Display/NotesDisplayTable';
import { ModelMapping } from '../../../../utils/utils';
import NewNoteModal from '../../../../components/modals/NewNoteModal/NewNoteModal';

import { EditOutlined, FolderOpenOutlined, PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';

export default function PeopleDetail({ slug }) {
    const [person, setPerson] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    useEffect(() => {
        axios.get(`persons/${slug}`)
            .then(response => {
                console.log(response.data)
                setPerson(response.data);
            })
            .catch(err => {

            })
    }, [])
    const onSubmitNoteHandler = (e, text) => {
        axios.post(`notes/`, {
            object_id: person.id,
            model_name: 'person',
            text: text
        });
    }

    return (
        <>
            {person &&
                <>
                    <Layout className={styles.layout}>
                        <Layout.Header>
                            <Row justify="space-between" align="middle">
                                <h1 style={{ color: "white" }}>{person.first_name}</h1>
                                <Button type="primary" onClick={e => { navigate(`/people/${slug}/edit`) }} icon={<EditOutlined />}>Edit Person</Button>
                            </Row>
                        </Layout.Header>
                        <Layout.Content className={styles.layout_content}>
                            <Tabs defaultActiveKey="1">
                                <Tabs.TabPane tab="People Details" key="1">
                                    <Row>
                                        <Descriptions title={
                                            <Row justify="space-between">
                                                {`${person.last_name}, ${person.first_name}`}
                                                {person.employee_id && <Button type="primary" icon={<FolderOpenOutlined/>} onClick={()=>navigate(`/employees/${person.employee_id}`)}>HR File</Button>}
                                            </Row>
                                            }
                                        >
                                            <Descriptions.Item label="Email">{person.email_address}</Descriptions.Item>
                                            <Descriptions.Item label="Primary Phone Number">{phoneDisplay(person?.primary_phone_number)}</Descriptions.Item>
                                            <Descriptions.Item label="Secondary Phone Number">{phoneDisplay(person?.secondary_phone_number)}</Descriptions.Item>
                                        </Descriptions>
                                    </Row>
                                    <Row>
                                        <Descriptions title="Address">
                                            <Descriptions.Item label="Address Line 1">{person.address_line_1}</Descriptions.Item>
                                            <Descriptions.Item label="Address Line 2">{person.address_line_2}</Descriptions.Item>
                                            <Descriptions.Item label="City">{person.city}</Descriptions.Item>
                                            <Descriptions.Item label="State">{person.state}</Descriptions.Item>
                                            <Descriptions.Item label="Zip Code">{person.zip_code}</Descriptions.Item>
                                        </Descriptions>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Notes" key="2">
                                    <Row justify="space-between">
                                        <Typography.Title level={3}>Notes</Typography.Title>
                                        <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => setVisibleModal(true)}>Add Note</Button>
                                    </Row>
                                    <NotesDisplayTable model={ModelMapping.person} objectId={person.id} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Attachments" key="3">
                                    <Col span={11}>
                                        <Row justify="space-between" align="middle">
                                            <Typography.Title level={3}>Attachments</Typography.Title>
                                            <Button type="primary" icon={<UploadOutlined />}>Upload Attachment</Button>
                                        </Row>
                                        <Row>
                                            <Alert message="No Attachments" type="info" showIcon />
                                        </Row>
                                    </Col>
                                </Tabs.TabPane>
                            </Tabs>
                        </Layout.Content>
                        <NewNoteModal
                            visible={visibleModal}
                            setVisible={setVisibleModal}
                            onSubmitHandler={onSubmitNoteHandler}
                        />
                    </Layout>
                </>
            }
        </>
    )
}
