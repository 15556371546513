import React, { createContext, useReducer, useEffect } from 'react';
import 'antd/dist/antd.css';
import rootReducer from './store/reducers/rootReducer';
import rootState from './store/states/rootState';

import { Router } from '@reach/router'
import DashboardView from './views/private/DashboardView/DashboardView';
import NavbarWrapper from './containers/NavbarWrapper/NavbarWrapper';

import CompanyList from './views/private/Companies/CompanyList/CompanyList';
import CompanyCreate from './views/private/Companies/CompanyCreate/CompanyCreate';
import CompanyDetail from './views/private/Companies/CompanyDetail/CompanyDetail';
import CompanyEdit from './views/private/Companies/CompanyEdit/CompanyEdit';

import PeopleCreate from './views/private/People/PeopleCreate/PeopleCreate';
import PeopleList from './views/private/People/PeopleList/PeopleList';
import PeopleDetail from './views/private/People/PeopleDetail/PeopleDetail';
import PeopleEdit from './views/private/People/PeopleEdit/PeopleEdit';

import TrademarkList from './views/private/Trademarks/TrademarkList/TrademarkList';
import TrademarkCreate from './views/private/Trademarks/TrademarkCreate/TrademarkCreate';
import TrademarkDetail from './views/private/Trademarks/TrademarkDetail/TrademarkDetail';
import TrademarkEdit from './views/private/Trademarks/TrademarkEdit/TrademarkEdit';

import CopyrightList from './views/private/Copyrights/CopyrightList/CopyrightList';
import CopyrightCreate from './views/private/Copyrights/CopyrightCreate/CopyrightCreate';
import CopyrightDetail from './views/private/Copyrights/CopyrightDetail/CopyrightDetail';
import CopyrightEdit from './views/private/Copyrights/CopyrightEdit/CopyrightEdit';

import PatentList from './views/private/Patents/PatentList/PatentList';
import PatentCreate from './views/private/Patents/PatentCreate/PatentCreate';
import PatentDetail from './views/private/Patents/PatentDetail/PatentDetail';
import PatentEdit from './views/private/Patents/PatentEdit/PatentEdit';

import ContractList from './views/private/Contracts/ContractList/ContractList';
import ContractCreate from './views/private/Contracts/ContractCreate/ContractCreate';

import InvoiceCreate from './views/private/Invoices/InvoiceCreate/InvoiceCreate';
import InvoiceList from './views/private/Invoices/InvoiceList/InvoiceList';
import InvoiceDetail from './views/private/Invoices/InvoiceDetail/InvoiceDetail';

import Settings from './views/private/Settings/Settings';
import GlobalContext from './store/GlobalContext';
import LoginView from './views/public/Auth/LoginView/LoginView';
import RegisterView from './views/public/Auth/RegisterView/RegisterView';
import ForgotPasswordView from './views/public/Auth/ForgotPasswordView/ForgotPasswordView';
import PrivateRoute from './components/routes/PrivateRoute';

import UnauthorizedView from './views/public/UnauthorizedView/UnauthorizedView';
import ContractTemplateCreate from './views/private/Contracts/ContractTemplateCreate/ContractTemplateCreate';
import LandingPage from './views/public/LandingPage/LandingPage';
import HumanResourcesIndex from './views/private/HumanResources/HumanResourcesIndex/HumanResourcesIndex';
import EmployeeCreate from './views/private/HumanResources/EmployeeCreate/EmployeeCreate';
import EmployeeDetail from './views/private/HumanResources/EmployeeDetail/EmployeeDetail';
import InvoiceEdit from './views/private/Invoices/InvoiceEdit/InvoiceEdit';
import RegistrationConfirmView from './views/public/Auth/RegistrationConfirmView/RegistrationConfirmView';

function App() {
  const [globalState, globalDispatch] = useReducer(rootReducer, rootState);

  return (
    <div className="App">
      <GlobalContext.Provider value={{ globalState, globalDispatch }}>

        <Router>
          <LandingPage path='/' />
          <PrivateRoute component={DashboardView} path='/dashboard' navbarComponent={true} />

          {/* Company Routes */}
          <PrivateRoute component={CompanyCreate} path='/companies/new' navbarComponent={true} />
          <PrivateRoute component={CompanyList} path='/companies' navbarComponent={true} />
          <PrivateRoute component={CompanyDetail} path='/companies/:slug' navbarComponent={true} />
          <PrivateRoute component={CompanyEdit} path='/companies/:slug/edit' navbarComponent={true} />

          {/* People Routes */}
          <PrivateRoute component={PeopleCreate} path='/people/new' navbarComponent={true} />
          <PrivateRoute component={PeopleList} path='/people' navbarComponent={true} />
          <PrivateRoute component={PeopleDetail} path='/people/:slug' navbarComponent={true} />
          <PrivateRoute component={PeopleEdit} path='/people/:slug/edit' navbarComponent={true} />


          {/* Human Resources */}
          <PrivateRoute component={HumanResourcesIndex} path='/human-resources' navbarComponent={true} />

          {/* Employee Routes */}
          <PrivateRoute component={EmployeeCreate} path='/employees/new' navbarComponent={true}/>
          <PrivateRoute component={EmployeeDetail} path='/employees/:slug' navbarComponent={true} />
          
          {/* Trademark Routes */}
          <PrivateRoute component={TrademarkList} path='/trademarks' navbarComponent={true} />
          <PrivateRoute component={TrademarkCreate} path='/trademarks/new' navbarComponent={true} />
          <PrivateRoute component={TrademarkDetail} path='/trademarks/:slug' navbarComponent={true} />
          <PrivateRoute component={TrademarkEdit} path='/trademarks/:slug/edit' navbarComponent={true} />

          {/* Copyright Routes */}
          <PrivateRoute component={CopyrightList} path='/copyrights' navbarComponent={true} />
          <PrivateRoute component={CopyrightCreate} path='/copyrights/new' navbarComponent={true} />
          <PrivateRoute component={CopyrightDetail} path='/copyrights/:slug' navbarComponent={true} />
          <PrivateRoute component={CopyrightEdit} path='/copyrights/:slug/edit' navbarComponent={true} />

          {/* Patent Routes */}
          <PrivateRoute component={PatentList} path='/patents' navbarComponent={true} />
          <PrivateRoute component={PatentCreate} path='/patents/new' navbarComponent={true} />
          <PrivateRoute component={PatentDetail} path='/patents/:slug' navbarComponent={true} />
          <PrivateRoute component={PatentEdit} path='/patents/:slug/edit' navbarComponent={true} />

          {/* Contract Routes */}
          <PrivateRoute component={ContractList} path='/contracts' navbarComponent={true} />
          <PrivateRoute component={ContractCreate} path='/contracts/new' navbarComponent={true} />
          <PrivateRoute component={ContractTemplateCreate} path='/contracts/templates/new' navbarComponent={true} />

          <PrivateRoute component={InvoiceList} path='/invoices' navbarComponent={true} />
          <PrivateRoute component={InvoiceCreate} path='/invoices/new' navbarComponent={true} />
          <PrivateRoute component={InvoiceDetail} path='/invoices/:slug' navbarComponent={true} />
          <PrivateRoute component={InvoiceEdit} path='/invoices/:slug/edit' navbarComponent={true} />

          <PrivateRoute component={Settings} path='/settings' navbarComponent={true} />
          
          <LoginView path="/login" />
          <RegisterView path="/register" />
          <ForgotPasswordView path="/forgot-password" />
          <UnauthorizedView path="/unauthorized" />
          <RegistrationConfirmView path="/confirm-registration" />
        </Router>

      </GlobalContext.Provider>
    </div>
  );
}

export default App;
