import { Input, Table, Form, Row, InputNumber, Button } from 'antd'
import React, { useState, useEffect } from 'react'

// const calculateTotal = (form, row) => {
//     return form.getFieldValue(`quantity-${row}`) * form.getFieldValue(`price_per_unit-${row}`);
// }

export default function InvoiceTable({form, defaultLineItems}) {
    const [lineItems, setLineItems] = useState([0])
    const [count, setCount] = useState(0);
    const [totals, setTotals] = useState([0]);

    const addLineItem = () => {
        setLineItems([...lineItems, count + 1])
        setCount(count + 1);
    }

    const removeLineItem = index => {
        let newLineItems = [...lineItems];
        newLineItems.splice(newLineItems.indexOf(index), 1)
        setLineItems(newLineItems);
    }


    const changeValueHandler = (e, fieldName) => {
        console.log(lineItems, fieldName)
        form.setFieldsValue({ [fieldName]: e.target.value });    
    }

    const updateTotal = index => {
        let quantity = form.getFieldValue(`quantity-${index}`);
        let unitPrice = form.getFieldValue(`unit_price-${index}`);

        quantity = quantity ? parseFloat(quantity) : 0;
        unitPrice = unitPrice ? parseFloat(unitPrice) : 0;

        const newTotals = [...totals];
        newTotals[index-1] = quantity * unitPrice;
        setTotals(newTotals);
    }

    const columns = [
        {
            title: "Item/Service",
            render: (text, record) => {
                const fieldName = `item_or_service-${record}`;
                return (
                    <Form.Item
                        onChange={(e)=>{changeValueHandler(e, fieldName)}}
                        name={fieldName}
                    >
                        <Input />
                    </Form.Item>
                )
            },
        },
        {
            title: "Description",
            render: (text, record) => {
                const fieldName = `description-${record}`;
                return (
                    <Form.Item
                        onChange={(e)=>{changeValueHandler(e, fieldName)}}
                        name={fieldName}
                    >
                        <Input />
                    </Form.Item>
                )
            },
        },
        {
            title: "Quantity",
            render: (text, record) => {
                const fieldName = `quantity-${record}`;
                return (
                    <Form.Item
                        onChange={(e)=>{
                            changeValueHandler(e, fieldName);
                            updateTotal(record);
                        }}
                        name={fieldName}
                    >
                        <InputNumber />
                    </Form.Item>
                )
            },
        },
        {
            title: "Price/Unit",
            render: (text, record) => {
                const fieldName = `unit_price-${record}`;
                return (
                    <Form.Item
                        onChange={(e)=>{
                            changeValueHandler(e, fieldName);
                            updateTotal(record);
                        }}
                        name={fieldName}                    
                    >
                        <InputNumber />
                    </Form.Item>
                )
            },
        },
        {
            title: "Total",
            render: (text, record) => {
                return <span>${totals[record - 1]}</span>;
            },
        },
        {
            title: <Button type="primary" onClick={addLineItem}>Add Row</Button>,
            render: (text, record) => {
                return <Button type="danger" onClick={()=>{removeLineItem(record)}}>Delete</Button>;
            },
        },
        
    ]
    return (
        <Table columns={columns} dataSource={lineItems} />
    )
}
