import React from 'react';
import { Layout, message } from 'antd';
import styles from '../../../../utils/GlobalStyles.module.scss';
import axios from '../../../../utils/clientAxios';
import CopyrightForm from '../../../../components/forms/CopyrightForm/CopyrightForm';

const success = (name) => {
    message.success(`${name} has been added`);
};
const error = () => {
    message.error("This copyright could not be added. Try again")
}

export default function CopyrightCreate() {
    const onSubmitHandler = data => {
        axios.post(`copyrights/`,
            data)
            .then(response => {
                success(response.data.name);
            })
            .catch(err => {
                error();
                console.log(err)
            })
    }
    return (
        <Layout>
            <Layout.Header>
                <h1 style={{ color: "white" }}>Add Copyright</h1>
            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                <CopyrightForm onSubmitHandler={onSubmitHandler} />
            </Layout.Content>
        </Layout>

    )
}
