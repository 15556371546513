import React, { useState } from 'react'
import { Button, Typography, Alert, Row, Col } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons'
import Modal from 'antd/lib/modal/Modal';
import Dragger from 'antd/lib/upload/Dragger';
import axios from '../../../utils/clientAxios';
import FileListDisplay from '../FileListDisplay/FileListDisplay';

export default function FileComponent({ model, objectId }) {
    const [modalVisible, setModalVisible] = useState(false);

    const openModal = e => {
        setModalVisible(true);
    }
    const closeModal = e => {
        setModalVisible(false);
    }
    const onChangeHandler = data => {
        console.log(data);
    }
    // const actionURL = `${process.env.REACT_APP_BASE_URL}files/`;
    const actionHandler = file => {
        const reader = new FileReader();
        // reader.
        reader.readAsDataURL(file.originFileObj);
        console.log(reader.result);
        axios.post('files/', {
            item: reader.result,
            model: model,
            object_id: objectId
        });
    }
    const props = {
        name: 'file',
        multiple: true,
        action: `${process.env.REACT_APP_BASE_URL}files/`,
        withCredentials: true,
        data: file=>({
            item: file,
            model: model,
            object_id: objectId
        })
        // beforeUpload: (file, fileList) => {
        //     console.log(file);
        // },
        // transformFile: (file)=>{
        //     console.log(file)
        // },
        // onChange: (info) => {
        //     setFileList(info.fileList);
        // },
      };
    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={3}>Files</Typography.Title>
                <Button type="primary" icon={<UploadOutlined />} onClick={openModal} >Upload File</Button>
            </Row>
            <Row>
                <FileListDisplay model={model} objectId={objectId}/>
            </Row>
            {/* TODO Change upload to only upload on submit */}
            <Modal
                title="Upload File"
                visible={modalVisible}
                onOk={closeModal}
                onCancel={closeModal}
            >
                <Dragger
                 {...props}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file(s) to this area to upload</p>
                </Dragger>
            </Modal>
        </>
    )
}
