import React, { useState } from 'react';
import { Menu, Layout, Divider, Typography, Grid, Drawer } from 'antd';
import { UserOutlined, PaperClipOutlined, TrademarkOutlined, CopyOutlined, CopyrightOutlined, SettingOutlined, LogoutOutlined, MenuOutlined, BankOutlined, LaptopOutlined, DollarCircleOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';
import axios from '../../utils/clientAxios';
import styles from './DashboardNavbar.module.scss';
import SubMenu from 'antd/lib/menu/SubMenu';

const { Sider } = Layout;
const { useBreakpoint } = Grid;


export default function DashboardNavbar() {
    const [visible, setVisible] = useState(false);

    const screens = useBreakpoint();
    const logoutHandler = e => {
        axios.get('auth/logout/')
            .then(response => {
                if (response.status == '200') {
                    navigate('/login');
                }
            })
            .catch(err => {

            })
    }
    const toggleDrawer = e => {
        setVisible(!visible);
    }
    const closeDrawer = e => {
        setVisible(false);
    }
    const [collapsed, setCollapsed] = useState(false);
    return (
        <Sider 
            width={225}
            collapsible 
        >
            <Menu theme="dark" mode="inline" style={{ marginTop: "5rem" }}>
                <Typography.Title level={3} style={{ color: "white", textAlign: "center" }} onClick={() => { navigate('/dashboard/') }}>Proxint</Typography.Title>
                <Menu.Item onClick={e => { navigate('/people/') }} icon={<UserOutlined />}>People</Menu.Item>
                <Menu.Item active onClick={e => { navigate('/companies/') }} icon={<BankOutlined />}>Companies</Menu.Item>
                {/* <Menu.Item style={{ textAlign: "center", padding: 0 }}>
                </Menu.Item> */}
                <Menu.Item onClick={e => { navigate('/contracts/') }} icon={<PaperClipOutlined />}>Contracts</Menu.Item>
                <SubMenu icon={<LaptopOutlined />} title="Intellectual Property">
                    <Menu.Item onClick={e => { navigate('/trademarks/') }} icon={<TrademarkOutlined />}>Trademarks</Menu.Item>
                    <Menu.Item onClick={e => { navigate('/copyrights/') }} icon={<CopyrightOutlined />}>Copyrights</Menu.Item>
                    <Menu.Item onClick={e => { navigate('/patents/') }} icon={<CopyOutlined />}>Patents</Menu.Item>
                </SubMenu>
                <Menu.Item onClick={e => { navigate('/invoices/') }} icon={<DollarCircleOutlined />}>Invoices/Billing</Menu.Item>
                <Menu.Item onClick={e => { navigate('/human-resources/') }} icon={<FolderOpenOutlined />}>Human Resources</Menu.Item>
                <Divider />
                <Menu.Item onClick={e => { navigate('/settings/') }} icon={<SettingOutlined />}>Settings</Menu.Item>
                <Menu.Item icon={<LogoutOutlined />} onClick={logoutHandler}>Logout</Menu.Item>
            </Menu>
        </Sider>
    )
}
