import { SET_PERMISSIONS } from '../actions/permissionActions';


export default (state, action) => {
    switch (action.type) {
        case SET_PERMISSIONS:
            return {
                ...state,
                permissionState: {
                    ...state.permissionState
                }
            };
        default:
            return {
                ...state
            }
    }
}
