import { Form, Select } from 'antd';
import { BankOutlined, UserOutlined } from '@ant-design/icons';

import React, { useState, useEffect } from 'react'
import axios from '../../../utils/clientAxios';

export default function CompanyPersonInput({fieldName, form, defaultValue, label, mode}) {
    const [companiesPersons, setCompaniesPersons] = useState([]);
    useEffect(() => {
        axios.get(`companies-persons/`)
            .then(response=>{
                if(defaultValue) {
                    if (mode === 'multiple'){
                        setCompaniesPersons([...defaultValue.map((item, index)=>({company_person:item})), ...response.data])
                    }
                    else{
                        setCompaniesPersons([{company_person: defaultValue}, ...response.data]);
                    }
                }
                else{
                    setCompaniesPersons(response.data.slice(0,5))
                }
            })
    }, [])

    const onChange = () => {
    }

    const onSearch = data => {
        axios.get(`companies-persons/?search=${data}`)
        .then((response) => {
            setCompaniesPersons(response.data.slice(0, 5))
        })
    }

    return (
        <Form.Item
            label={label}
            name={fieldName}
            initialValue={(mode === 'multiple' && defaultValue)? defaultValue.map((item, index)=>(JSON.stringify(item))) : JSON.stringify(defaultValue)}
        >   
            <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select a person"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={false}
                notFoundContent={null}
                defaultValue={(mode === 'multiple' && defaultValue) ? defaultValue.map((item, index)=>(JSON.stringify(item))) : JSON.stringify(defaultValue)}
                mode={mode}        
            >
                {   companiesPersons.length > 0 &&
                    companiesPersons.map((item, index)=>{
                        const { company_person } = item;
                        console.log("ITEM", item)
                        if (company_person.type === 'person') {
                            return (
                                <Select.Option key={index} value={JSON.stringify(company_person)}>
                                    <span><UserOutlined/></span>
                                    {company_person.value}
                                </Select.Option>
                            )
                        }
                        return (
                            <Select.Option key={index} value={JSON.stringify(company_person)}>
                                <span><BankOutlined/></span>
                                {company_person.value}
                            </Select.Option>
                        )
                    })
                }
            </Select>
        </Form.Item>
    )
}
