import moment from 'moment';

export const dateFormat = 'YYYY/MM/DD';

export const ModelMapping = {
    trademark: 'trademark',
    patent: 'patent',
    person: 'person',
    copyright: 'copyright',
    contract: 'contract',
    company: 'company',
    employee: 'employee'
}

export const displayDateOrToday = (date) => {
    if (!date) {
        return moment();
    }
    return moment(date, dateFormat)
}

export const displayDateOrReturnNull = date => (date ? moment(date, dateFormat) : null);

export const alphabeticOrderSorter = (a, b) => {
    var nameA = a.toUpperCase();
    var nameB = b.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
}

export const dateSorter = (a, b) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
}