import React, { useContext, useState } from 'react'
import { Grid, Layout, Row, Col, Form, Typography, Card, Input, Button, message } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Link, navigate, useLocation } from '@reach/router';
import styles from './LoginView.module.scss';
import GlobalContext from '../../../../store/GlobalContext';
import axios from '../../../../utils/clientAxios';

export default function LoginView() {
    const [form] = Form.useForm()
    const { globalDispatch } = useContext(GlobalContext);
    const location = useLocation();

    const onSubmitHandler = data => {
        axios.post('auth/login/', data)
            .then(response => {
                message.success("Login Successful")
                console.log(response.data)
                globalDispatch({ type: 'login', payload: { email: response.data.email, isAuthenticated: true } })

            }).then(() => {
                if (location.search !== ""){
                    const redirect = location.search.split("=")[1];
                    console.log(redirect);
                    navigate(redirect);
                }
                else{
                    navigate('/dashboard')
                }
            })
            .catch(err => {
                if(err.response.status >= 400 && err.response.status < 500) {
                    message.error("Invalid credentials.")
                }
            })
    }
    return (
        <Layout className={styles.login_layout}>
            <Row justify="center" align="middle" className={styles.row}>
                <Col>
                    <Card title="Login" className={styles.login_card}>
                        <Form
                            form={form}
                            onFinish={onSubmitHandler}>
                            <Form.Item name="email">
                                <Input prefix={<MailOutlined />} placeholder="Email Address" />
                            </Form.Item>
                            <Form.Item name="password">
                                <Input type="password" prefix={<LockOutlined />} placeholder="Password" />
                            </Form.Item>
                            <Button type="primary" block htmlType="submit">Login</Button>
                        </Form>
                        <Link to="/register">Not a member? Sign up Today</Link>
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}
