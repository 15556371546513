import React, { useState, useEffect, useContext } from 'react'
import { STORE_USER_DATA } from '../../store/actions/authActions';
import axios from '../../utils/clientAxios';
import { Redirect, useLocation } from '@reach/router';
import NavbarWrapper from '../../containers/NavbarWrapper/NavbarWrapper';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import GlobalContext from '../../store/GlobalContext';
import InvoiceSendModal from '../modals/InvoiceSendModal/InvoiceSendModal';

export default function PrivateRoute({ component: Component, navbarComponent, path, ...rest }) {
    const [check, setCheck] = useState('loading');

    const { globalState, globalDispatch } = useContext(GlobalContext);
    const { authState } = globalState;
    const { authDispatch } = globalDispatch;
    const location = useLocation();

    useEffect(() => {
        axios.post('auth/is-authenticated/')
            .then(response => {
                globalDispatch({ type: STORE_USER_DATA, payload: { ...response.data, isAuthenticated: true } });
            })
            .catch(err => {
                globalDispatch({ type: STORE_USER_DATA, payload: { isAuthenticated: false } });
            })
    }, []);
    useEffect(() => {
        console.log(authState.isAuthenticated);
        if (authState.isAuthenticated) {
            setCheck('authenticated');
        }
        else if (authState.isAuthenticated === false) {
            setCheck('unauthenticated');
        }
        else {
            setCheck('loading');
        }
    }, [authState.isAuthenticated]);

    if (check === 'loading') {
        return <FullPageLoader />;
    } else if (check === 'authenticated') {
        const mainComponent = (
            <>
                <Component {...rest} />
                
                {/* Modals */}
                <InvoiceSendModal />
            </>
        )
        if (navbarComponent) {
            return (
                <NavbarWrapper>
                    {mainComponent}
                </NavbarWrapper>
            )
        }
        return (
            <>
                {mainComponent}
            </>
        )
    }
    return <Redirect from={path} to={`/login?next=${location.pathname}`} noThrow />

}