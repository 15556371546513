import React, { useState, useEffect, useContext } from 'react'
import { Layout, Table, Button, Row, Col, Spin, Space, Modal, Popconfirm, message } from 'antd'
import { LoadingOutlined, PlusCircleOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { navigate, Link } from '@reach/router';

import GlobalContext from '../../../../store/GlobalContext';
import axios from '../../../../utils/clientAxios';
import { alphabeticOrderSorter, dateSorter } from '../../../../utils/utils';
import globalStyles from '../../../../utils/GlobalStyles.module.scss';
import { trademarkChoices } from '../../../../utils/choiceMapping';
import TrademarkStatusTag from '../../../../components/TrademarkStatusTag/TrademarkStatusTag';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;



export default function TrademarkList({ slug }) {
    const [trademarks, setTrademarks] = useState(false);
    const [pages, setPages] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [selectedToDelete, setSelectedToDelete] = useState(0);

    const handleDelete = (e, id) => {
        axios.delete(`trademarks/${id}/`)
            .then(response => {
                message.success("Trademark Deleted");
                setTrademarks(trademarks.filter(trademark => trademark.id !== id))
            })
            .catch(err => {
                
            })
        }
    const columns = [
        {
            title: "Type",
            dataIndex: "trademark_type",
            sorter: (a, b) => alphabeticOrderSorter(a.trademark_type, b.trademark_type),
            render: (text, record) => {
                return (
                    trademarkChoices.typeMapping[text]
                )
            }
        },
        {
            title: "Jurisdiction",
            dataIndex: "jurisdiction",
            // sorter: (a, b) => alphabeticOrderSorter(a.trademark_type, b.trademark_type),
            // render: (text, record) => {
            //     return (
            //         trademarkChoices.typeMapping[text]
            //     )
            // }
        },
        {
            title: "Serial Number",
            dataIndex: "serial_number"
        },
        {
            title: "Registration Number",
            dataIndex: "registration_number"
        },
        {
            title: "Registration Date",
            dataIndex: "registration_date"
        },
        {
            title: "Application Date",
            dataIndex: "application_date",
            sorter: (a, b) => dateSorter(a.name, b.name)

        },
        {
            title: "Actions",
            dataIndex: "id",
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <EditTwoTone onClick={() => navigate(`/trademarks/${record.id}/edit`)} />
                        <Popconfirm
                            title="Are you sure you want to delete?"
                            onConfirm={e => {
                                handleDelete(e, record.id)
                            }}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteTwoTone twoToneColor="#f5222d" />
                        </Popconfirm>
                    </Space>
                )
            }
        }

    ]
    const { globalState: { authState } } = useContext(GlobalContext);

    const handleTableChange = (pagination, filters, sorters) => {
        // console.log("test", pagination, sorters, filters);
        // axios.get(`trademarks/?offset=${pagination.current * 10 - 10}`)
        //     .then(response => {
        //         setPages(Math.ceil(response.data.count / 10));
        //         setTrademarks(response.data.results)
        //     })
        //     .catch(err => { })
    }

    useEffect(() => {
        axios.get('trademarks/')
            .then(response => {
                // console.log(Math.ceiling(response.data.count / 10));
                setTrademarks(response.data.results);
            })
            .catch(err => { })
    }, [])
    return (
        <Layout className={globalStyles.layout}>
            <Layout.Header>
                <Row justify="space-between" align="middle">
                    <h1 style={{ color: "white" }}>Trademarks</h1>
                    <Button type="primary" onClick={e => { navigate('/trademarks/new') }} icon={<PlusCircleOutlined />}>Add Trademark</Button>
                </Row>
            </Layout.Header>
            <Layout.Content className={globalStyles.layout_content}>
                {trademarks ?
                    <Table columns={columns} dataSource={trademarks} onChange={handleTableChange} />
                    :
                    <Spin indicator={antIcon} />
                }
            </Layout.Content>
        </Layout>
    )
}

