
import { Form, Input, Layout, message } from 'antd';
import React from 'react'
import { layout, tailFormItemLayout } from '../../../../utils/layouts';
import styles from '../../../../utils/GlobalStyles.module.scss';
import CompanyForm from '../../../../components/forms/CompanyForm/CompanyForm';
import axios from '../../../../utils/clientAxios';
import { navigate } from '@reach/router';

export default function CompanyCreate() {
    const onSubmitHandler = data => {
        axios.post('companies/', data)
            .then(response=>{
                const { id } = response.data;
                message.success("Company created");
                navigate(`/companies/${id}`);                
            })
            .catch(err=>{
                message.error("Error. Try again")
            })
    }
    
    return (
        <Layout className={styles.layout}>
            <Layout.Header>
                <h1 style={{ color: "white" }}>Add Company</h1>
            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                <CompanyForm onSubmitHandler={onSubmitHandler} />
            </Layout.Content>
        </Layout>
    )
}
