import React, { useEffect, useState } from 'react'
import { Layout, Row, Typography, Space, Col, Button, Divider, Alert, Descriptions, Tabs } from 'antd';
import { EditOutlined, PlusCircleOutlined, UploadOutlined } from '@ant-design/icons'
import axios from '../../../../utils/clientAxios';
import styles from '../../../../utils/GlobalStyles.module.scss';
import FileComponent from '../../../../components/Display/FileComponent/FileComponent';
import { ModelMapping } from '../../../../utils/utils';
import NewNoteModal from '../../../../components/modals/NewNoteModal/NewNoteModal';
import NotesDisplayTable from '../../../../components/Display/NotesDisplayTable';
import { navigate } from '@reach/router';

export default function PatentDetail({ slug }) {
    const [patent, setPatent] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    useEffect(() => {
        axios.get(`patents/${slug}`)
            .then(response => {
                setPatent(response.data)
            })
            .catch(err => {

            })
    }, [])
    const onSubmitNoteHandler = (e, text) => {
        axios.post(`notes/`, {
            object_id: patent.id,
            model_name: 'patent',
            text: text
        });
    }

    return (
        <>
            {
                patent &&
                <>
                    <Layout className={styles.layout}>
                        <Layout.Header>
                            <Row justify="space-between" align="middle">
                                <h1 style={{ color: "white" }}>{patent.title}</h1>
                                <Button type="primary" href={`/patents/${slug}/edit/`} icon={<EditOutlined/>}>Edit</Button>
                            </Row>
                        </Layout.Header>
                        <Layout.Content className={styles.layout_content}>
                            <Tabs defaultActiveKey="1">
                                <Tabs.TabPane tab="Patent Details" key="1">
                                    <Row>
                                        <Descriptions title="Patent Details">
                                            <Descriptions.Item label="Title">{patent.title}</Descriptions.Item>
                                            <Descriptions.Item label="Product">{patent.product}</Descriptions.Item>
                                            <Descriptions.Item label="Firm Reference Number">{patent.firm_reference_number}</Descriptions.Item>
                                            <Descriptions.Item label="Status">{patent.status}</Descriptions.Item>
                                            <Descriptions.Item label="Jurisdiction">{patent.jurisdiction}</Descriptions.Item>
                                            <Descriptions.Item label="Patent Type">{patent.patent_type}</Descriptions.Item>
                                            <Descriptions.Item label="Application Number">{patent.application_number}</Descriptions.Item>
                                            <Descriptions.Item label="Publication Number">{patent.publication_number}</Descriptions.Item>
                                            <Descriptions.Item label="Patent Number">{patent.patent_number}</Descriptions.Item>
                                        </Descriptions>
                                        <Descriptions title="Important Dates">
                                            <Descriptions.Item label="Filing Date">{patent.filing_date}</Descriptions.Item>
                                            <Descriptions.Item label="Priority Date">{patent.priority_date}</Descriptions.Item>
                                            <Descriptions.Item label="Issue Date">{patent.issue_date}</Descriptions.Item>
                                            <Descriptions.Item label="Renewal Fee Date">{patent.renewal_fee_date}</Descriptions.Item>
                                            <Descriptions.Item label="Expiration Date">{patent.expiration_date}</Descriptions.Item>
                                        </Descriptions>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Notes" key="2">
                                    <Row justify="space-between">
                                        <Typography.Title level={3}>Notes</Typography.Title>
                                        <Button type="primary" icon={<PlusCircleOutlined/>} onClick={()=>{setVisibleModal(true)}}>Add Note</Button>
                                    </Row>
                                    <NotesDisplayTable model={ModelMapping.patent} objectId={patent.id} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Files" key="3">
                                    <FileComponent model={ModelMapping.patent} objectId={patent.id} />
                                </Tabs.TabPane>
                            </Tabs>
                        </Layout.Content>
                    </Layout>
                    <NewNoteModal
                        visible={visibleModal}
                        setVisible={setVisibleModal}
                        onSubmitHandler={onSubmitNoteHandler}
                    />
                </>
            }
        </>
    )

}
