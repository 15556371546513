import React from 'react';
import { Spin, Layout, Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Grid from 'antd/lib/card/Grid';

const spinner = <LoadingOutlined style={{ fontSize: 100 }} spin />;

export default function FullPageLoader() {
    return (
        <Layout style={{ height: "100vh", width: "100vw" }}>
            <Grid>
                <Row align="middle" justify="center" style={{ height: "100vh", width: "100vw" }}>
                    <Col>
                        <Grid item>
                            <Spin indicator={spinner} />
                        </Grid>
                    </Col>
                </Row>
            </Grid>
        </Layout>
    )
}
