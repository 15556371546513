import React, { useState } from 'react'
import { Form, Input, Modal } from 'antd';

export default function NewNoteModal({ submitMethod, visible, setVisible, onSubmitHandler }) {
    const [text, setText] = useState("");
    const onCancelHandler = e => {
        setVisible(false);
    }

    return (
        <Modal
            title="Title"
            visible={visible}
            onOk={(e) => {
                onSubmitHandler(e, text);
            }}
            onCancel={onCancelHandler}
        >
            <p>Add a note</p>
            <Form>
                <Form.Item>
                    <Input.TextArea rows={5} onChange={(e) => { setText(e.target.value) }} />
                </Form.Item>
            </Form>
        </Modal>)
}
