import React, { useState, useEffect } from 'react'
import { Layout, Table, Button, Row, Col, Spin, Space, Modal, Popconfirm, message, Tabs, Typography } from 'antd'
import { LoadingOutlined, PlusCircleOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import axios from '../../../../utils/clientAxios';
import { navigate, Link } from '@reach/router';
import styles from '../../../../utils/GlobalStyles.module.scss';
import ContractTemplateTable from '../../../../components/tables/ContractTemplateTable/ContractTemplateTable';
import ContractTable from '../../../../components/tables/ContractTable/ContractTable';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function ContractList() {
    const [contracts, setContracts] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedToDelete, setSelectedToDelete] = useState(0);

    const handleDelete = (e, id) => {
        axios.delete(`contracts/${id}/`) 
            .then(response => {
                message.success("Contract Deleted");
                setContracts(contracts.filter(contract => contract.id !== id))
            })
            .catch(err => {

            })
    }
    
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            render: (text, record) => {
                return (
                    <Link to={`/contracts/${record.id}`}>{record.name}</Link>
                )
            }
        },
        {
            title: "Type",
            dataIndex: "contract_type"
        },
        {
            title: "Actions",
            dataIndex: "id",
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <EditTwoTone />
                        <Popconfirm
                            title="Are you sure you want to delete?"
                            onConfirm={e => {
                                handleDelete(e, record.id)
                            }}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteTwoTone twoToneColor="#f5222d" />
                        </Popconfirm>
                    </Space>
                )
            }
        }

    ]
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/contracts/`)
            .then(response => {
                setContracts(response.data)
            })
            .catch(err => { })
    }, [])
    return (
        <Layout>
            <Modal
                title="Basic Modal"
                visible={showModal}
                onOk={handleDelete}
                onCancel={e => { setShowModal(false) }}
                okText="Delete"
                okButtonProps={{ danger: true }}
            >
                Are you sure you want to delete this Contract?
            </Modal>
            <Layout.Header>
                <Row justify="space-between" align="middle">
                    <h1 style={{ color: "white" }}>Contracts</h1>
                    <div>
                        <Space>
                        </Space>
                    </div>
                </Row>
            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                <>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab = "Contracts" key="1">
                        <Row justify="end">
                            <Button type="primary" onClick={e => { navigate('/contracts/new') }} icon={<PlusCircleOutlined />}>Add Contract</Button>
                        </Row>
                        <ContractTable />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab = "Templates" key="2">
                        <Row justify="end">
                            <Button type="primary" onClick={e => { navigate('/contracts/templates/new') }} icon={<PlusCircleOutlined />}>Add Template</Button>
                        </Row>
                        <ContractTemplateTable />
                    </Tabs.TabPane>
                </Tabs>
                </>
            </Layout.Content>
        </Layout>
    )
}

