export const trademarkChoices = {
    statusMapping: {
        pending: 'Pending',
        registered: 'Registered',
        expired: 'Expired',
        abandoned: 'Abandoned',
        other: 'Other'
    },
    typeMapping: {
        wordmark: 'Wordmark',
        design: 'Design',
        other: 'Other'
    },
    classesMapping: {
        'chemicals_001': '001 Chemicals',
        'paints_002': '002 Paints',
        'cosmetics_003': '003 Cosmetics and cleaning products',
        'lubricants_004': '004 Lubricants and fuels',
        'pharmaceuticals_005': '005 Pharmaceuticals',
        'metal_goods_006': '006 Metal goods',
        'machinery_007': '007 Machinery',
        'hand_tools_008': '008 Hand Tools',
        'electrical_009': '009 Electrical and Scientific Apparatus',
        'medical_apparatus_010': '010 Medical Apparatus',
        'environmental_011': '011 Environmental Control Apparatus',
        'vehicles_012': '012 Vehicles',
        'firearms_013': '013 Firearms',
        'jewelry_014': '014 Jewelry',
        'musical_015': '015 Musical Instruments',
        'paper_016': '016 Paper Goods and Printed Matter',
        'rubber_017': '017 Paper Goods',
        'leather_018': '018 Leather Goods',
        'non_metallic_019': '019 Non-Metallic Building Materials',
        'furniture_020': '020 Furniture and Articles Not Otherwise',
        'housewares_021': '021 Housewares and Glass',
        'cordage_022': '022 Cordage and Fibers',
        'yarns_023': '023 Yarns and Threads',
        'fabrics_024': '024 Fabrics',
        'clothing_025': '025 Clothing',
        'fancy_026': '026 Fancy Goods',
        'floor_027': '027 Floor Coverings',
        'toys_028': '028 Toys and Sporting Goods',
        'meats_029': '029 Meats and Processed Foods',
        'staple_030': '030 Staple Foods',
        'natural_agriculture_031': '031 Natural Agricultural Products',
        'light_beverages_032': '032 Light Beveragess',
        'wines_and_spirits_033': '033 Wines and Spirits',
        'smokers_034': '034 Smokers Articles',
        'advertising_035': '035 Advertising and Business',
        'insurance_036': '036 Insurance and Financial',
        'construction_037': '037 Construction and Repair',
        'communication_038': '038 Communication',
        'transportation_039': '039 Transportation and Storage',
        'material_040': '040 Material Treatment',
        'education_041': '041 Education and Entertainment',
        'computer_042': '042 Computer, Scientific and Legal',
        'hotels_043': '043 Hotels and Restaurants',
        'medical_044': '044 Medical, Beauty and Agricultural',
        'personal_045': '045 Personal',
    }
}

export const copyrightChoices = {
    statusMapping: {
        pending: 'Pending',
        registered: 'Registered'
    }
}