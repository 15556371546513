import React, { useState } from 'react'
import { Button, Modal, Popconfirm } from 'antd'
import {navigate, useLocation} from '@reach/router';

export default function CancelFormButton({previous, defaultPrevious, form}) {
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);

    const onClickHandler = () => {
        const navigateTo = previous ? previous : defaultPrevious;
        if (form.isFieldsTouched()) {
            setConfirmModalVisible(true);            
        }
        else{
            navigate(navigateTo);
        }
    }

    const okModalHandler = () => {
        const navigateTo = previous ? previous : defaultPrevious;
        navigate(navigateTo);
    }

    return (
        <>
            <Button type="primary" onClick={onClickHandler} danger>Cancel</Button>
            <Modal
                visible={confirmModalVisible}
                title="Are you sure?"
                onOk={okModalHandler}
                onCancel={()=>setConfirmModalVisible(false)}
            >
                You are about to leave this page, even though you have made changes. Are you sure you want to leave?
            </Modal>
        </>
    )
}
