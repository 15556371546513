import React, { useState, useEffect } from 'react'
import { Layout, message } from 'antd'
import PatentForm from '../../../../components/forms/PatentForm/PatentForm'
import axios from '../../../../utils/clientAxios';
import styles from '../../../../utils/GlobalStyles.module.scss';
import { navigate } from '@reach/router';

export default function PatentEdit({ slug }) {
    const [patent, setPatent] = useState(false);
    useEffect(() => {
        axios.get(`patents/${slug}`)
            .then(response => {
                setPatent(response.data)
            })
            .catch(() => {

            })
    }, [])
    const onSubmitHandler = data => {
        data.filing_date = data.filing_date.format('YYYY-MM-DD');
        data.priority_date = data.priority_date.format('YYYY-MM-DD');
        data.issue_date = data.issue_date.format('YYYY-MM-DD');
        data.renewal_fee_date = data.renewal_fee_date.format('YYYY-MM-DD');
        data.expiration_date = data.expiration_date.format('YYYY-MM-DD');
        axios.put(`patents/${slug}/`,
            data)
            .then(response => {
                message.success("Successfully Updated");
                navigate(`/patents/${slug}`)
            })
            .catch(err => {
                message.error();
            })
    };
    return (
        <>
            {
                patent &&
                <Layout>
                    <Layout.Header>
                        <h1 style={{ color: "white" }}>Edit Patent</h1>
                    </Layout.Header>
                    <Layout.Content className={styles.layout_content}>
                        <PatentForm onSubmitHandler={onSubmitHandler} patent={patent} />
                    </Layout.Content>
                </Layout>
            }
        </>
    )
}
