import React, { useState, useEffect } from 'react';
import { Layout, Form, Input, Button, Space, DatePicker, message, Select } from 'antd';
import { layout, tailFormItemLayout } from '../../../utils/layouts';
import { displayDateOrToday } from '../../../utils/utils';
import axios from '../../../utils/clientAxios';
import CompanyPersonInput from '../../FormInputs/CompanyPersonInput/CompanyPersonInput';

export default function TrademarkForm({ onSubmitHandler, trademark }) {
    const [form] = Form.useForm();
    const [statuses, setStatuses] = useState(false);
    const [types, setTypes] = useState(false);
    const [classes, setClasses] = useState(false);
    const [jurisdictions, setJurisdictions] = useState(false);
    const [publicationEndDateDisabled, setPublicationEndDateDisabled] = useState(false);

    useEffect(() => {
        axios.get(`core/choices/?model=trademarks,countries`)
            .then(response => {
                setStatuses(response.data.trademarks.trademark_statuses);
                setTypes(response.data.trademarks.trademark_types);
                setClasses(response.data.trademarks.trademark_classes);
                setJurisdictions({
                    ...response.data.countries.countries,
                    ...response.data.countries.eu,
                    ...response.data.countries.wipo
                })
            })
            .catch(err => {

            })
    }, [])

    const checkPublicationDisabled = () => {
        setPublicationEndDateDisabled(form.getFieldValue('jurisdiction') === 'US')
    }
    const trademarkFormSubmitHandler = data => {
        // If there is one class provided, it will just be a string, so convert to an array
        if (typeof data.classes === 'string') {
            data.classes = [data.classes];
        }
        data.owner = data.owner ? JSON.parse(data?.owner) : null
        onSubmitHandler(data);
    }
    return (
        <Form
            {...layout}
            form={form}
            onFinish={trademarkFormSubmitHandler}
        >
            <Form.Item
                label="Name"
                name="name"
                initialValue={trademark?.name}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Status"
                name="status"
                initialValue={trademark?.status}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <Select>
                    {statuses && Object.keys(statuses).map((item, index) => {
                        return <Select.Option key={index} value={item}>{statuses[item]}</Select.Option>
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label="Jurisdiction"
                name="jurisdiction"
                initialValue={trademark?.jurisdiction}
                rules={[{ required: true, message: "This field is required" }]}
                onChange={checkPublicationDisabled}
            >
                <Select>
                    {jurisdictions && Object.keys(jurisdictions).map((item, index) => {
                        return <Select.Option key={index} value={item}>{jurisdictions[item]}</Select.Option>
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label="Type"
                name="trademark_type"
                initialValue={trademark?.trademark_type}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <Select>
                    {types && Object.keys(types).map((item, index) => {
                        return <Select.Option key={index} value={item}>{types[item]}</Select.Option>
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label="Serial Number"
                name="serial_number"
                initialValue={trademark?.serial_number}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Application Date"
                name="application_date"
                initialValue={displayDateOrToday(trademark?.application_date)}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                label="Office Actions Date"
                name="office_actions_date"
                initialValue={displayDateOrToday(trademark?.office_actions_date)}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                label="Response Date"
                name="response_date"
                initialValue={displayDateOrToday(trademark?.response_date)}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                label="Publication Start Date"
                name="publication_start_date"
                initialValue={displayDateOrToday(trademark?.publication_start_date)}
                rules={[
                    { required: true, message: "This field is required" },
                ]}
            >
                <DatePicker/>
            </Form.Item>
            <Form.Item
                label="Publication End Date"
                name="publication_end_date"
                initialValue={displayDateOrToday(trademark?.publication_end_date)}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <DatePicker disabled={publicationEndDateDisabled} />
            </Form.Item>
            <Form.Item
                label="Registration Date"
                name="registration_date"
                initialValue={displayDateOrToday(trademark?.registration_date)}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                label="Notice of Allowance"
                name="notice_of_allowance"
                initialValue={displayDateOrToday(trademark?.notice_of_allowance)}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                label="Extension Due Date"
                name="extension_due_date"
                initialValue={displayDateOrToday(trademark?.extension_due_date)}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                label="Renewal Date"
                name="renewal_date"
                initialValue={displayDateOrToday(trademark?.renewal_date)}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                label="Registration Number"
                name="registration_number"
                initialValue={trademark?.registration_number}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Next Steps"
                name="next_steps"
                initialValue={trademark?.next_steps}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Description of Goods/Services"
                name="description_of_goods"
                initialValue={trademark?.description_of_goods}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <Input />
            </Form.Item>
            <CompanyPersonInput defaultValue={trademark?.owner} label="Owner" fieldName="owner" form={form} />
            <Form.Item
                label="Classes"
                name="classes"
                initialValue={trademark?.classes}
                rules={[{ required: true, message: "This field is required" }]}
            >
                <Select
                    showSearch
                    mode="multiple"
                >
                    {classes && Object.keys(classes).map((item, index) => {
                        return <Select.Option key={index} value={item}>{classes[item]}</Select.Option>
                    })}
                </Select>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
                <Space size="middle">
                    <Button type="primary" htmlType="submit">Submit</Button>
                    <Button type="primary" danger href={trademark? `/trademarks/${trademark.id}`: `/trademarks`}>Cancel</Button>
                </Space>
            </Form.Item>
        </Form>)
}
