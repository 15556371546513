import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Select, Space, Row } from 'antd'
import { BackwardOutlined, BankOutlined, ForwardOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons'
import StripeContainer from '../../../containers/StripeContainer/StripeContainer';
import { useStripe } from '@stripe/react-stripe-js';
import axios from '../../../utils/clientAxios';

export default function ChoosePlanForm({form, setCurrentStep}) {
    const stripe = useStripe();
    const [subscriptionType, setSubscriptionType] = useState(false);

    const onSubmitHandler = data => {
        if(stripe) {
            axios.post('payments/create-checkout-session/', data)
            .then(response=>{
                stripe.redirectToCheckout({sessionId: response.data.session});
            })
            .catch(err=>{

            })
        }
        // setCurrentStep(2);
    }
    
    return (
            <Form
                form={form}
                onFinish={onSubmitHandler}
            >
                <Form.Item
                    label="Subscription Type"
                    name="subscription_type"           
                >
                    <Select
                        onChange={setSubscriptionType}
                    >
                        <Select.Option value={process.env.REACT_APP_STRIPE_PER_EMPLOYEE_PID}>Per Employee</Select.Option>                    
                        <Select.Option value={process.env.REACT_APP_STRIPE_SMALL_PACKAGE_PID}>
                            5 Licenses | $6,000/year
                        </Select.Option>    
                        <Select.Option value={process.env.REACT_APP_STRIPE_MEDIUM_PACKAGE_PID}>
                            10 Licenses | $10,000/year
                        </Select.Option>    
                        <Select.Option value={process.env.REACT_APP_STRIPE_LARGE_PACKAGE_PID}>
                            25 Licenses | $25,000/year
                        </Select.Option>    
                        <Select.Option value={process.env.REACT_APP_STRIPE_UNLIMITED_PACKAGE_PID}>
                            Unlimited | $45,000/year
                        </Select.Option>    
                    </Select>
                </Form.Item>

                {/* Per Employee */}
                {subscriptionType === process.env.REACT_APP_STRIPE_PER_EMPLOYEE_PID &&
                    <Form.Item
                    label="Number of Employees"
                    name="number_of_employees"
                    >
                        <Input/>
                    </Form.Item>
                }

                <Row justify="center">
                    <Space>
                        <Button type="primary" htmlType="submit" icon={<ForwardOutlined/>}>Pay</Button>
                    </Space>
                </Row>
            </Form>
    )
}
