import React from 'react'

import { navigate } from '@reach/router'
import { Button, Layout, Row } from 'antd'
import styles from '../../../../utils/GlobalStyles.module.scss';

import EmployeeTable from '../../../../components/tables/EmployeeTable/EmployeeTable'
import { PlusCircleOutlined } from '@ant-design/icons'

export default function HumanResourcesIndex() {
    return (
        <Layout className={styles.layout}>
            <Layout.Header>
                <Row justify="space-between" align="middle">
                    <h1 style={{ color: "white" }}>Employees</h1>
                    <Button type="primary" icon={<PlusCircleOutlined/>} onClick={()=>navigate('/employees/new')}>Add Employee</Button>
                </Row>
            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                <EmployeeTable />            
            </Layout.Content>
        </Layout>

)
}
