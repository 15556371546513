import React, {useState, useEffect} from 'react'
import { Button, Form, Input, Row, Select, Space, Col, Typography } from 'antd';
import { tailFormItemLayout } from '../../../utils/layouts';
import axios from '../../../utils/clientAxios';
import styles from '../../../utils/GlobalStyles.module.scss';
import FileViewer from 'react-file-viewer';

export const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 10 },
    },
};

export default function ContractForm({onSubmitHandler, templateId}) {
    const [form] = Form.useForm();
    const [contractTemplates, setContractTemplates] = useState([]);
    const [showVariables, setShowVariables] = useState(false);
    const [contractTemplateId, setContractTemplateId] = useState(0);
    
    useEffect(()=>{
        axios.get('contracts/templates')
            .then(response=>{
                setContractTemplates(response.data);
                setShowVariables(!!templateId);
            })
            .catch(err=>{
                
            })
        }, []);
        
    const changeTemplateHandler = e => {
        setShowVariables(true);
    }
    const handleSubmit = data => {
        onSubmitHandler(data);
    }
    return (
        <Row>
            <Col span={12}>
                <Form
                    {...layout}
                    form={form}
                    onFinish={handleSubmit}
                >
                <Form.Item
                    label="Name"
                    name="name"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Template"
                    name="template"
                    initialValue={templateId ? templateId : null}
                >
                    <Select onChange={changeTemplateHandler}>
                        {contractTemplates && contractTemplates.map((item, index) => {
                            return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                        })}
                    </Select>
                </Form.Item>
                {showVariables &&
                    <>
                    {contractTemplates.find(element=>element.id===form.getFieldValue('template')).variable_names.map((item, index)=>{
                        return(
                            <Form.Item
                                name={item}
                                label={item}
                            >
                                <Input/>
                            </Form.Item>   
                        )
                    })}

                    </>
                }   
                <Form.Item {...tailFormItemLayout}>
                    <Space size="middle">
                        <Button type="primary" htmlType="submit">Create Contract</Button>
                    </Space>
                </Form.Item>
            </Form>
            </Col>
            <Col span={12}>
                {showVariables && 
                    <>
                        <Typography.Title>{contractTemplates.find(element=>element.id===form.getFieldValue('template'))['name']}</Typography.Title>
                        <div style={{height:"65vh", padding: "10px 35px", backgroundColor: "lightgrey"}}>
                            <FileViewer
                                fileType="docx"
                                filePath={contractTemplates.find(element=>element.id===form.getFieldValue('template'))['url']}
                            />
                        </div>
                    </>
                }   
            </Col>
        </Row>

    )
}
