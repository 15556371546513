import React, { useContext } from 'react'
import GlobalContext from '../../../store/GlobalContext';
import { Layout, Tabs, Typography, Input, Form, Space, Button, Table, Divider, Row, Col, Descriptions, Card } from 'antd'
import { layout, tailFormItemLayout } from '../../../utils/layouts';
import axios from '../../../utils/clientAxios';
import styles from '../../../utils/GlobalStyles.module.scss';
import ChangePasswordForm from '../../../components/forms/ChangePasswordForm/ChangePasswordForm';
import OrganizationManagementTable from '../../../components/OrganizationManagementTable/OrganizationManagementTable';

export default function Settings() {
    const { globalState: { authState } } = useContext(GlobalContext);
    const { organization } = authState;

    const [form] = Form.useForm();

    const onSubmitHandler = data => {
    }

    return (
        <Layout className={styles.layout}>
            <Layout.Header>
                <h1 style={{ color: "white" }}>Settings</h1>
            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Account Information" key="1">
                        <Row>
                            <Col span={11}>
                                <Card title="Change Email Address">
                                    <Form
                                        form={form}
                                        onFinish={onSubmitHandler}
                                    >
                                        <Form.Item
                                            label="Email Address"
                                            name="new_email_address"
                                        // rules={[{ required: true, message: "This field is required" }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item {...tailFormItemLayout}>
                                            <Space size="middle">
                                                <Button type="primary" htmlType="submit">Change</Button>
                                            </Space>
                                        </Form.Item>
                                    </Form>
                                </Card>
                            </Col>
                            <Col span={12} offset={1}>
                                <Card title="Change Password">
                                    <ChangePasswordForm />
                                </Card>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Organization Management" key="2">
                        <Row>
                            <Col span={7}>
                                <Card title="Organization Information">
                                    <Descriptions>
                                        <Descriptions.Item span={3} label="Name">{organization?.name}</Descriptions.Item>
                                        <Descriptions.Item span={3} label="Your Plan">Test</Descriptions.Item>
                                    </Descriptions>
                                </Card>
                            </Col>
                            <Col span={16} offset={1}>
                                <OrganizationManagementTable people={[{
                                    first_name: "John",
                                    last_name: "Smith",
                                    email: "john@smith.com",
                                    status: "Admin"
                                }]} />
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </Layout.Content>
        </Layout >
    )
}
