import { Form, Select } from 'antd';
import React, { useState, useEffect } from 'react'
import axios from '../../../utils/clientAxios';

export default function StateDropdownInput({fieldName, form, defaultValue, label}) {
    const [states, setStates] = useState([]);
    useEffect(() => {
        axios.get('core/choices?model=states')
            .then(response=>{
                setStates(response.data.states.all);
            })
            .catch(err=>{
                
            })
    }, [])

    const onChange = e => {
        form.setFieldsValue({ [fieldName]: e });
    }

    return (
        <Form.Item  
            label={label}
            name={fieldName}
            initialValue={defaultValue}
        >
            <>
                {states.length !== 0 &&
                    <Select
                    onChange={onChange}
                    defaultValue={defaultValue}
                    >
                        {Object.keys(states).map((key, index)=>{
                            return <Select.Option value={key}>{states[key]}</Select.Option>
                        })}
                    </Select>
                }
            </>
        </Form.Item>
    )
}
