import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Space, DatePicker, Switch, Select } from 'antd';
import { layout, tailFormItemLayout } from '../../../utils/layouts';
import { displayDateOrToday } from '../../../utils/utils';
import axios from '../../../utils/clientAxios';
import PersonSelect from '../../FormInputs/PersonSelect/PersonSelect';

export default function CopyrightForm({ onSubmitHandler, copyright }) {
    const [form] = Form.useForm();
    const [copyrightStatuses, setCopyrightStatuses] = useState(false);
    const [jurisdictions, setJurisdictions] = useState(false);
    useEffect(() => {
        axios.get('core/choices/?model=copyrights,countries')
            .then(response => {
                setCopyrightStatuses(response.data.copyrights.copyright_statuses);
                setJurisdictions(response.data.countries.countries)
            })
            .catch(err => {

            })
    }, [])
    const copyrightFormSubmitHandler = data => {
        data.first_publication_date = data.first_publication_date.format('YYYY-MM-DD');
        data.issue_date = data.issue_date.format('YYYY-MM-DD');
        data.expiration_date = data.expiration_date.format('YYYY-MM-DD');
        onSubmitHandler(data);
    }
    return (
        <Form
            {...layout}
            form={form}
            onFinish={copyrightFormSubmitHandler}
        >

            <Form.Item
                label="Product"
                name="product"
                initialValue={copyright?.product}
            // rules={[{ required: true, message: "This field is required" }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Status"
                name="status"
                initialValue={copyright?.status}
            // rules={[{ required: true, message: "This field is required" }]}
            >
                <Select>
                    {Object.keys(copyrightStatuses).map((item, index) => {
                        return <Select.Option key={index} value={item}>{copyrightStatuses[item]}</Select.Option>
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label="Title"
                name="title"
                initialValue={copyright?.title}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Jurisdiction"
                name="jurisdiction"
                initialValue={copyright?.jurisdiction}
            >
                <Select
                    showSearch
                >
                    {jurisdictions && Object.keys(jurisdictions).map((item, index) => {
                        return <Select.Option key={index} value={item}>{jurisdictions[item]}</Select.Option>
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label="Number"
                name="number"
                initialValue={copyright?.number}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="First Publication Date"
                name="first_publication_date"
                initialValue={displayDateOrToday(copyright?.first_publication_date)}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                label="Issue Date"
                name="issue_date"
                initialValue={displayDateOrToday(copyright?.issue_date)}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                label="Expiration Date"
                name="expiration_date"
                initialValue={displayDateOrToday(copyright?.expiration_date)}
            >
                <DatePicker format='YYYY-MM-DD' />
            </Form.Item>
            <Form.Item
                label="Is Made For Hire"
                name="is_made_for_hire"
                initialValue={copyright?.is_made_for_hire}
            >
                <Switch />
            </Form.Item>
            <PersonSelect form={form} fieldName='author' defaultValue={copyright?.author} label="Registered Agent Status" />
            <Form.Item {...tailFormItemLayout}>
                <Space size="middle">
                    <Button type="primary" htmlType="submit">Submit</Button>
                    <Button type="primary" danger href={copyright ? `/copyrights/${copyright.id}` : `/copyrights`}>Cancel</Button>
                </Space>
            </Form.Item>
        </Form>
    )
}
