import React from 'react';
import { Form, Input, Space, Button } from 'antd';
import { layout, tailFormItemLayout } from '../../../utils/layouts';
import axios from '../../../utils/clientAxios';

export default function ChangePasswordForm() {
    const [form] = Form.useForm();
    const onSubmitHandler = data => {
        axios.post('auth/change-password/', data)
            .then(response => {

            })
            .catch(err => {

            })
    }
    return (
        <Form
            form={form}
            onFinish={onSubmitHandler}
        >
            <Form.Item
                label="Old Password"
                name="old_password"
            // rules={[{ required: true, message: "This field is required" }]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                label="New Password"
                name="new_password"
            // rules={[{ required: true, message: "This field is required" }]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                label="Confirm New Password"
                name="confirm_new_password"
            // rules={[{ required: true, message: "This field is required" }]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
                <Space size="middle">
                    <Button type="primary" htmlType="submit">Change</Button>
                </Space>
            </Form.Item>
        </Form>
    )
}
