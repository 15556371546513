import { SendOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';
import { Button, Form, Input, message, Modal, Typography } from 'antd'
import React, { useContext } from 'react'
import { HIDE_MODAL, SHOW_MODAL } from '../../../store/actions/modalActions';
import GlobalContext from '../../../store/GlobalContext'
import axios from '../../../utils/clientAxios';

export default function InvoiceSendModal() {
    const [form] = Form.useForm();
    const { globalState: { modalState }, globalDispatch } = useContext(GlobalContext);
    const { contactPerson } = modalState;

    const onOkModalHandler = () => {
        const formData = form.getFieldsValue();
        axios.post(`invoices/${modalState.invoiceId}/send/`)
            .then(()=>{
                message.success('Sent');
                globalDispatch({type: HIDE_MODAL})
                navigate(`/invoices/${modalState.invoiceId}`)
            })
    }

    return (
        <Modal
            title="Title"
            visible={modalState.showModal}
            onCancel={()=>globalDispatch({type: HIDE_MODAL})}
            onOk={onOkModalHandler}
            okText="Send"
        >
            <p>{modalState.text}</p>
            <Form
                layout="vertical"
                form={form}
            >
                <Form.Item
                    label="First Name"
                    name="first_name"   
                    initialValue={contactPerson?.first_name}
                >
                    <Input defaultValue={contactPerson?.first_name}/>
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="last_name"   
                    initialValue={contactPerson?.last_name}
                >
                    <Input defaultValue={contactPerson?.last_name}/>
                </Form.Item>
                <Form.Item
                    label="Email Address"
                    name="email"   
                    initialValue={contactPerson?.email_address}
                >
                    <Input defaultValue={contactPerson?.email_address}/>
                </Form.Item>
            </Form>
        </Modal>
    )
}
