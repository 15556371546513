import { Layout } from 'antd';
import React from 'react';
import ContractTemplateForm from '../../../../components/forms/ContractTemplateForm/ContractTemplateForm';
import axios from '../../../../utils/clientAxios';
import styles from '../../../../utils/GlobalStyles.module.scss';

export default function ContractTemplateCreate() {
    const onSubmitHandler = data => {
        axios.post(`contracts/templates/`, data)
            .then(response=>{
                
            })  
            .catch(err=>{

            })
    }
    return (
       <Layout className={styles.layout}>
           <Layout.Header>New Template</Layout.Header>
           <Layout.Content className={styles.layout_content}>
                <ContractTemplateForm onSubmitHandler={onSubmitHandler} />
           </Layout.Content>
       </Layout>
    )
}