import { Button, Table } from 'antd';
import { DownloadOutlined } from '@ant-design/icons'
import React, {useState, useEffect} from 'react'
import axios from '../../../utils/clientAxios';

export default function ContractTable() {
    const [contracts, setContracts] = useState([]);
    const downloadContract = id => {
        axios({
            url: `contracts/download/${id}/`,
            method: 'GET',
            // responseType: 'arraybuffer', 
            // headers: {
            //     'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
            //     'Content-Type': 'application/application/vnd.openxmlformats-officedocument.wordprocessingml.document' 
            // }
        })
            .then(response=>{
                const { url } = response.data;
                // const content = response.headers['content-type'];
                // let blob = new Blob([response.data], { type: content }),
                // url = window.URL.createObjectURL(blob)
                window.open(url);
            })
            .catch(err=>{

            })
        console.log("Downloading ", id);
    }
    const columns = [
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "Template",
            render: (text, record) => {
                return <span>{record.template.name}</span>
            }
        },
        {
            title: "Created",
            dataIndex: "created"
        },
        {
            title: "",
            render: (text, record)=>{
                return <Button type="primary" icon={<DownloadOutlined />}onClick={()=>{downloadContract(record.id)}}>Download</Button>
            }
        }
    ]

    useEffect(()=>{
        axios.get('contracts/')
            .then(response=>{
                setContracts(response.data);
            })
            .catch(err=>{})
    }, [])
    
    return (
        <Table columns={columns} dataSource={contracts}/>
    )
}
