import React, { useState, useEffect } from 'react'
import { Layout, Table, Button, Row, Col, Spin, Space, Modal, Popconfirm, message } from 'antd'
import { LoadingOutlined, PlusCircleOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { navigate, Link } from '@reach/router';
import CopyrightSearch from '../../../../components/search/CopyrightSearch/CopyrightSearch';
import axios from '../../../../utils/clientAxios';
import { alphabeticOrderSorter, dateSorter } from '../../../../utils/utils';
import styles from '../../../../utils/GlobalStyles.module.scss';

import CopyrightStatusTag from '../../../../components/CopyrightStatusTag/CopyrightStatusTag';

const antIcon = <LoadingOutlined style={{ fontSize: 24, textAlign: "center" }} spin />;



export default function CopyrightList() {
    const [copyrights, setCopyrights] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedToDelete, setSelectedToDelete] = useState(0);
    const handleDelete = (e, id) => {
        axios.delete(`copyrights/${id}/`)
            .then(response => {
                message.success("Copyright Deleted");
                setCopyrights(copyrights.filter(copyright => copyright.id !== id))
            })
            .catch(err => {

            })
    }
    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            render: (text, record) => {
                return (
                    <Link to={`/copyrights/${record.id}`}>{record.title}</Link>
                )
            },
            sorter: (a, b) => alphabeticOrderSorter(a.title, b.title)
        },
        {
            title: "Product",
            dataIndex: "product"
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (value, record) => (<CopyrightStatusTag status={value} />)
        },
        {
            title: "First Publication Date",
            dataIndex: "first_publication_date"
        },
        {
            title: "Number",
            dataIndex: "number"
        },
        {
            title: "Expiration Date",
            dataIndex: "expiration_date"
        },
        {
            title: "Actions",
            dataIndex: "id",
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <EditTwoTone onClick={e => navigate(`/copyrights/${record.id}/edit`)} />
                        <Popconfirm
                            title="Are you sure you want to delete?"
                            onConfirm={e => {
                                handleDelete(e, record.id)
                            }}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteTwoTone twoToneColor="#f5222d" />
                        </Popconfirm>
                    </Space>
                )
            }
        }

    ]
    useEffect(() => {
        axios.get(`copyrights/`)
            .then(response => {
                setCopyrights(response.data)
            })
            .catch(err => { })
    }, [])
    return (
        <Layout className={styles.layout}>
            <Modal
                title="Basic Modal"
                visible={showModal}
                onOk={handleDelete}
                onCancel={e => { setShowModal(false) }}
                okText="Delete"
                okButtonProps={{ danger: true }}
            >
                Are you sure you want to delete this Copyright?
            </Modal>
            <Layout.Header>
                <Row justify="space-between" align="middle">
                    <h1 style={{ color: "white" }}>Copyrights</h1>
                    <Button type="primary" onClick={e => { navigate('/copyrights/new') }} icon={<PlusCircleOutlined />}>Add Copyright</Button>
                </Row>
            </Layout.Header>
            <Layout.Content className={styles.layout_content}> 
                {copyrights ?
                    <Table columns={columns} dataSource={copyrights} />
                    :
                    <Spin indicator={antIcon} />
                }
            </Layout.Content>
        </Layout>
    )
}

