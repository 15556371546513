import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, DatePicker } from 'antd';
import InvoiceCreateTable from '../../tables/InvoiceCreateTable/InvoiceCreateTable';
import PersonSelect from '../../FormInputs/PersonSelect/PersonSelect';
import axios from '../../../utils/clientAxios';
import { navigate } from '@reach/router';

const createTransformedData = data => {
    const transformedData = {line_items: []}
    for(const key of Object.keys(data)) {
        let splitByHyphen = key.split('-')

        if(splitByHyphen.length == 2) {
            let index =  parseInt(splitByHyphen[1]);
            if (!transformedData.line_items[index]){
                transformedData.line_items[index] = {}
            }
            transformedData.line_items[index][splitByHyphen[0]] = data[key];
        }
        else{
            transformedData[key] = data[key];
        }
    }
    transformedData.line_items = transformedData.line_items.filter(item=>!!item);
    return transformedData;
}

export default function InvoiceForm({invoice}) {
    const [form] = Form.useForm();
 
    const onSubmitHandler = data => {
        data.date_of_invoice = data.date_of_invoice.format('YYYY-MM-DD');
        data.status = 'created';
        
        const transformedData = createTransformedData(data);

        axios.post('invoices/', transformedData)
            .then(response=>{
                const { id } = response.data;
                navigate(`/invoices/${id}`);
            })
            .catch(err=>{

            })
    }

    return (
        <Form
            form={form}   
            layout="vertical"
            onFinish={onSubmitHandler}
        >
            <Row>
                <Col>
                <PersonSelect form={form} fieldName="contact_person" label="Contact Person" />
                </Col>
                <Col>
                    <Form.Item
                        label="Invoice Date"
                        name="date_of_invoice"
                    >
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        label="Order Number"
                        name="number"
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <InvoiceCreateTable form={form} />
            </Row>
            <Button type="primary" htmlType="Submit">Create</Button>
        </Form>
    )
}
