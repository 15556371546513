import React, {useState, useEffect} from 'react'
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Descriptions, Layout, Row, Tabs, Typography } from 'antd';
import axios from '../../../../utils/clientAxios'
import styles from '../../../../utils/GlobalStyles.module.scss';
import { ModelMapping } from '../../../../utils/utils';
import NotesDisplayTable from '../../../../components/Display/NotesDisplayTable';
import NewNoteModal from '../../../../components/modals/NewNoteModal/NewNoteModal';
import FileComponent from '../../../../components/Display/FileComponent/FileComponent';

export default function EmployeeDetail({ slug }) {
    const [employee, setEmployee] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);

    useEffect(() => {
        axios.get(`employees/${slug}`)
            .then(response=>{
                setEmployee(response.data);
            })
            .catch(err=>{

            })
    }, [])
    const onSubmitNoteHandler = (e, text) => {
        axios.post(`notes/`, {
            object_id: employee.id,
            model_name: 'employee',
            text: text
        });
    }
    return (
        <>
            {employee && 
                <>
                    <Layout className={styles.layout}>
                        <Layout.Header>
                            <h1>{employee.person.first_name}</h1>
                        </Layout.Header>
                        <Layout.Content className={styles.layout_content}>
                            <Tabs defaultActiveKey="1">
                                <Tabs.TabPane tab="Employee Details" key="1">
                                    <Descriptions title="Human Resources">
                                        <Descriptions.Item label="Date of Birth">{employee?.dob}</Descriptions.Item>
                                        <Descriptions.Item label="Date of Hire">{employee?.hire_date}</Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions title="Contact Information">
                                        <Descriptions.Item label="Address Line 1">{employee?.person?.address_line_1}</Descriptions.Item>
                                        <Descriptions.Item label="Address Line 2">{employee?.person?.address_line_2}</Descriptions.Item>
                                        <Descriptions.Item label="City">{employee?.person?.city}</Descriptions.Item>
                                        <Descriptions.Item label="State">{employee?.person?.state}</Descriptions.Item>
                                    </Descriptions>
                                    </Tabs.TabPane>
                                <Tabs.TabPane tab="Notes" key="2">
                                    <Row justify="space-between">
                                        <Typography.Title level={3}>Notes</Typography.Title>
                                        <Button type="primary" icon={<PlusCircleOutlined/>} onClick={()=>setVisibleModal(true)}>Add Note</Button>
                                    </Row>
                                    <NotesDisplayTable model={ModelMapping.employee} objectId={employee.id} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Files" key="3">
                                    <FileComponent model={ModelMapping.employee} objectId={employee.id} />
                                </Tabs.TabPane>
                            </Tabs>
                        </Layout.Content>
                    </Layout>
                    <NewNoteModal
                        visible={visibleModal}
                        setVisible={setVisibleModal}
                        onSubmitHandler={onSubmitNoteHandler}
                    />
                </>
            }
        </>
    )
}
