import React, { useState, useEffect } from 'react'
import { Layout, message } from 'antd'
import PeopleForm from '../../../../components/forms/PeopleForm/PeopleForm'
import axios from '../../../../utils/clientAxios';
import styles from '../../../../utils/GlobalStyles.module.scss';
import { navigate } from '@reach/router';

export default function PeopleEdit({ slug }) {
    const [person, setPerson] = useState(false);
    const onSubmitHandler = data => {
        axios.put(`persons/${slug}/`, data)
            .then(response=>{
                message.success("Successfully Updated!");
                navigate(`/people/${slug}`);
            })
            .catch(err=>{
                message.error("There has been an error");
            })
    }
    useEffect(() => {
        axios.get(`persons/${slug}/`)
            .then(response => {
                setPerson(response.data);
                console.log("test", response.data)
            })
            .catch(err => {

            })
    }, [])
    return (
        <Layout className={styles.layout}>
            <Layout.Header>
                <h1>Edit Person</h1>
            </Layout.Header>
            <Layout.Content className={styles.layout_content}>
                {
                    person &&
                    <PeopleForm onSubmitHandler={onSubmitHandler} person={person} />
                }
            </Layout.Content>
        </Layout>
    )
}
