import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import axios from '../../../utils/clientAxios';

export default function RegisteredAgentStatusInput({fieldName, form, label, defaultValue}) {
    const [choices, setChoices] = useState([])
    useEffect(() => {
        axios.get('core/choices?model=companies')
            .then(response=>{
                setChoices(response.data.companies.registered_agent_statuses)
            })
    }, [])
    const onChange = e => {
        form.setFieldsValue({ [fieldName]: e });
    }

    return (
        <Form.Item
            label={label}
            name={fieldName}
            initialValue={defaultValue}
        >
            <Select
                onChange={onChange}
            >
                {Object.keys(choices).map((key, index)=>{
                    return <Select.Option value={key}>{choices[key]}</Select.Option>
                })}
            </Select>
        </Form.Item>
)
}
