import { Table } from 'antd'
import React from 'react'

export default function InvoiceLineItemTable({lineItems}) {

    const columns = [
        {
            title: "Number",
            dataIndex: "count"
        },
        {
            title: "Description",
            dataIndex: "description"
        },
        {
            title: "Quantity",
            dataIndex: "quantity"
        },
        {
            title: "Unit Price",
            dataIndex: "unit_price"
        },
        {
            title: "Total",
            dataIndex: "total"
        },
    ]
    return (
        <Table 
            dataSource={lineItems.map((item, index)=>{
                return {...item, count: index + 1}
            })} 
            columns={columns}
        />
    )
}
