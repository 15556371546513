import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import InvoiceForm from '../../../../components/forms/InvoiceForm/InvoiceForm';
import axios from '../../../../utils/clientAxios';
import styles from '../../../../utils/GlobalStyles.module.scss';

export default function InvoiceEdit({slug}) {
    const [invoice, setInvoice] = useState(false);

    useEffect(()=>{
        axios.get(`invoices/${slug}/`)
            .then(response=>{
                setInvoice(response.data);
            })
    }, [])

    return (
        <>
            {invoice && 
                <Layout className={styles.layout}>
                    <Layout.Header>
                        <h1 style={{ color: "white" }}>Create Invoice</h1>
                    </Layout.Header>
                    <Layout.Content className={styles.layout_content}>
                        <InvoiceForm invoice={invoice}/>
                    </Layout.Content>
                </Layout>
            }
        </>
    )
}
